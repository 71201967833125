@font-face{font-family:BRADHITC;src:url(../src/fonts/BRADHITC.TTF);}
@font-face{font-family:Gotham-Bold;src:url(../src/fonts/Gotham-Bold.otf);}
@font-face{font-family:trendsetter-Regular;src:url(../src/fonts/trendsetter-Regular.ttf);} 
@font-face{font-family:Raleway-ExtraBoldr;src:url(../src/fonts/Raleway-ExtraBold.ttf);}
@font-face{font-family:london-fillbold; src:url(../src/fonts/london-fillbold.ttf);}
@font-face{font-family:VoguerIIDemoRegular; src:url(../src/fonts/VoguerIIDemoRegular.ttf);}
@font-face{font-family:SingleDay-Regular; src:url(../src/fonts/SingleDay-Regular.ttf);} 
@font-face{font-family:MYRIADPRO-REGULAR; src:url(../src/fonts/MYRIADPRO-REGULAR.OTF);} 
@font-face{font-family:CenturyGothic; src:url(../src/fonts/CenturyGothic.ttf);} 
@font-face{font-family:LucidaHandwritingItalic; src:url(../src/fonts/LucidaHandwritingItalic.ttf);} 
@font-face{font-family:BRLNSR; src:url(../src/fonts/BRLNSR.TTF);} 
@font-face{font-family:FutuBdIt; src:url(../src/fonts/FutuBdIt.ttf);}
@font-face{font-family:BRITANIC; src:url(../src/fonts/BRITANIC.TTF);} 
@font-face{font-family:broadwayregular; src:url(../src/fonts/broadwayregular.ttf);}  
@font-face{font-family:Aller_Rg; src:url(../src/fonts/Aller_Rg.ttf);}  
@font-face{font-family:ChaparralProRegular; src:url(../src/fonts/ChaparralProRegular.otf);} 
@font-face{font-family:KitaharaScript; src:url(../src/fonts/KitaharaScript.ttf);} 
@font-face{font-family:cambria; src:url(../src/fonts/cambria.ttc);}
@font-face{font-family:Elephant; src:url(../src/fonts/Elephant.ttf);}
@font-face{font-family:Mishella; src:url(../src/fonts/Mishella.otf);}
body{font-size: 14px;    font-family: 'Raleway', sans-serif;} 
a{
    text-decoration: none !important;
}

.whatsapp_analytics-- img{
    width: 40px;
}
body {
    font-size: 14px;
    font-family: Raleway,sans-serif !important;
    overflow-x: hidden;
}
.header-menu-middle{
    display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
}
.row.desktop{
padding: 15px 0px;
}
.metting-contact-us{
    text-align: center;
    padding-top: 0px !important;             
    display: flex;
    justify-content: space-evenly;    
    align-items: center;         
}
.metting-contact-us button{
    border: 0px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 0px;
    text-transform: uppercase;
    padding: 10px 12px;
    background: #414141;
    color: #fff;
}

.header_bg_new ~ .main-header-section .header-top{ background-repeat: repeat;}

.header_bg_new ~ .footer_main_section footer{background-repeat: repeat; margin-top: 300px;}
.new_home_page{background: url(../src/img/HomeBG.jpg);background-size: contain;} 
.testing_main_frame{background:url(../src/img/TestingBG.jpg);    background-size: contain;}
p{font-size: 14px;} 
.hide_n{display: none !important;}

.slick-dots{z-index: 999 !important;}
.top-back-banner_all img{width: 65%;
margin: 0 auto;
margin-top: 2%;}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row- .bea-t.w_w{left: -15px !important;}
.makeup-middle-sect.Body-care.mencare_new p.te_sou_right{  text-align: right;
font-style: italic;
font-size: 19px; }
.makeup-middle-sect.Body-care.mencare_new p.precise_range{line-height: 50px;
letter-spacing: 2px;
word-spacing: 10px;
font-weight: 600;
margin-top: 10%;}
.makeup-middle-sect.Body-care.mencare_new p .bea-t{    font-family: VoguerIIDemoRegular;
font-size: 70px !important;
left: 25px !important;
top: 20px;}
.makeup-middle-sect.Body-care.mencare_new p{    line-height: 38px;}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row .bea-t{
left: 10px !important;
top: 0px;
}
#project-contact-form .form-group{
    margin-bottom: 20px;
}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row- .bea-t{
left: 5px !important;
top: 0px;
}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row .bea-t-right{width: 90%;}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row{    margin-top: 5%;}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row-{    margin-top: 5%;}
.makeup-middle-sect.Body-care.mencare .percare_care p .bea-t{     font-family: VoguerIIDemoRegular;    font-size: 120px;left: 0px;}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont p .bea-t{     font-family: VoguerIIDemoRegular;    font-size: 80px; left: -6px;    top: 25px;}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row:nth-child(1) p .bea-t{font-size: 120px;
left: 10px;}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row:nth-child(2) p .bea-t{

left: -32px;
top: 20px;
}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row- {
margin-top: 4%;
}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row p{   width: 95%;
margin: 0 auto;
display: inherit;
margin-top: 4%;
}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont p .bea-t-right{    width: 90%;}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row{ margin-top: 4%;}
.makeup-middle-sect .bea-t{font-family: 'Pinyon Script', cursive;
color: #000;
font-size: 90px;
width: 50px;
display: inline-block;
float: left;
top: -5px;
position: relative;
left: 5px;

}  
.makeup-middle-sect.Body-care.mencare p.te_sou_right{    
text-align: right;
font-style: italic;
font-size: 19px;
}
.makeup-middle-sect .bea-t-right{float: left;
width: 95%;}
.makeup-middle-sect{margin-top: 5%;}
.makeup-middle-sect  p{    font-family: 'Merriweather', serif;
font-size: 16px;
line-height: 30px;
color: #000;
text-align: justify;
display: inline-block;
width: 100%;
}
.makeup-middle-sect .lip_acc p{width: 90%;}
.makeup-middle-sect .lip_acc.eye p{}
.rech_now_cont{text-align: center;margin-top: 5%;}
.rech_now_cont p{text-align: center;}
.rech_now_cont h5{
font-size: 44px;
font-family: 'Merriweather', serif;
}
.moreover_mini .moreover_mini_left img{ width: 85%; }
.rech_now_cont p a{
font-style: normal;
background: #000;
font-family: 'Merriweather', serif;
color: #fff;
padding: 5px 25px;
display: inline-block;
font-size: 24px;
text-transform: uppercase;
margin-top: 10px;
margin-bottom: 3%;}
.makeup-middle-sect p.Using_natural{    margin-top: 10%;}
.makeup-middle-sect p.Using_natural .bea-t-right{width: 90%;}
.moreover_mini_right .bea-t-right.over{width: 90%;} 
.moreover_mini_left img{padding-left: 50px;}
.bea-t.remove{
visibility: hidden;
}
.clear{clear: both;}

.gobal-beauty{    
margin-bottom: 3%;
display: inline-block;
width: 100%;
background: url(https://ik.imagekit.io/vert/vertlaboratoire/makecut_RYqjCMnw0.jpg) no-repeat;
background-position: 100% 36%;
}
.gi_f_banner_water{
background: url(https://ik.imagekit.io/vert/vertlaboratoire/cust_kwADwkbzu.gif);
background-size: cover;
margin-bottom: 5%;
}
.makeup-middle-sect .lip_acc p.lip{margin-top: 60px;background: url(https://ik.imagekit.io/vert/vertlaboratoire/makecut3_jDGFclxYW.jpg) no-repeat;
background-position: 100% 36%;}
.gi_f_banner_water p{
width: 45%;
background:#beb69e6e;
padding: 15px 15px;
font-weight: 600;
line-height: 40px;
margin-top: 3%;
margin-bottom: 2%;
letter-spacing: 1px;}
.your_skin{
margin-bottom: 4%;
background: url(https://ik.imagekit.io/vert/vertlaboratoire/makecut2_aPIRFYpFf.jpg) no-repeat;
background-position: 0% 75%;
}
.your_skin .bea-t-right.deserves{width: 90%;}
.lip_acc{margin-bottom: 0%;}
.img_left img{padding-left: 50px;}
.makeup-middle-sect .lip_acc .bea-t-right{    width: 91%;}
.makeup-middle-sect .lip_acc p {
margin-top: 110px;
}

.makeup-middle-sect.Body-care p{font-family: 'Crimson Text', serif; font-size: 22px;}
.makeup-middle-sect.Body-care p .bea-t{font-family:london-fillbold;}
.makeup-middle-sect.Body-care .percare_care p .bea-t{font-size: 199px; left: -46px;}
.makeup-middle-sect.Body-care .percare_care{margin-top: 10%;}
.Gob_care{    margin-top: 5%;}
.makeup-middle-sect.Body-care .Gob_care p .bea-t{left: -20px;}
.makeup-middle-sect.Body-care .longer p .bea-t{left: -55px; top: 5px;}
.percise_main_frame p{    font-family: 'Cormorant Garamond', serif !important;
font-size: 24px !important;
color: #fff;
letter-spacing: 1px;
word-spacing: 7px;
line-height: 40px;}
.row.longer .col-md-4 img{width: 84%;}   
.makeup-middle-sect.Body-care .longer p{ margin-top: 12%;} 
.percise_main_frame{
background: url(https://ik.imagekit.io/vert/vertlaboratoire/body3_RILcPBBJJ.gif) no-repeat;
background-size: cover;
padding-top: 3%;
padding-bottom: 3%;
margin-top: 2%;  
}
.makeup-middle-sect.Body-care .green_na p .bea-t{left: -48px;
font-size: 70px;
top: 19px;}
.makeup-middle-sect.Body-care .green_na p{    width: 93%;}
.makeup-middle-sect.Body-care .green_na{    margin-top: 2%;
margin-bottom: 2%;}
.green_na_g img{width: 90%;}
.green_na_g .col-md-6:last-child img{ float: right; }  
.makeup-middle-sect.Body-care{margin-top:0%;padding-top: 1%; background: #fcf4f1;} 
.makeup-middle-sect.Body-care.mencare p{font-family: 'Vollkorn', serif;
font-size: 21px;line-height:35px;}



.ProcessManufacturing-main-page_head p{
font-size: 15px;
line-height: 26px;
}
.ProcessManufacturing-main-page_head h3{
font-size: 36px;
text-align: center;
margin-top: 3%;
margin-bottom: 3%;
}
.ProcessManufacturing_bottomfullFrame{
background: #cee7c7;
padding: 20px 20px;
margin-top: 15px;
margin-bottom: 20px;
}
.ProcessManufacturing_left_img_bottom{     
background: #f1dac8;
padding: 10px 10px;
width: 97%; 
margin-top: 5%;
}
.ProcessManufacturing_right_img_bottom{ 
background: #e1e4e9;
width: 98%;
margin-top: 5%;
margin-bottom: 13%;
padding: 10px;  
}
.top-heading-pcking{    margin-top: 2%;}
.top-heading-pcking h3{text-align: center;color: #3c4157; font-size: 36px;}
.top-heading-pcking p{margin-top: 1%; font-size: 17px; line-height: 26px;}
.bottom_boder_packing{ 
border: 5px solid #3b4157;
padding: 10px 10px;
width: 98%;
margin: 0 auto;
margin-bottom: 5%;      
}
.packing_right_frame{    
background: #3c4157;
color: #fff;
height: 100%;
padding: 20px 20px;
}
.packing_right_frame{
background: #3c4157;
color: #fff;
height: 100%;
padding: 20px 20px;
}
.row_bo_pack{
margin-top: 4%;
margin-bottom: 4%;
}

.testing_top_head h2{margin-bottom: 20px;
text-align: center;
margin-top: 2%;}
.testing_top_head p{
font-size: 15px;
line-height: 25px;
text-align: left;}
.testing_top_head{  margin-bottom: 2%; }
.testing_right_frame{ background: #eacbb9; padding: 20px; margin-top: 8%;}
.bottom_new_line img{    bottom: -26%;
left: 29%;
height: auto;
width: 500px;
position: absolute;}
.bottom_new_line{    position: relative;    margin-bottom: 42%;}
.bottom_new_line img{
bottom: -400px;
left: 30%;
height: auto;
width: 443px;
position: absolute;
}

.pri-to-section{}
.pri-to-section h2{    
font-family: Gotham-Bold;
font-size: 62px;
line-height: 65px;
color: #4f4f50;
text-align: center;
margin-top: 5%;
letter-spacing: 5px;
}
.pri-to-section p{   
text-align: center;
font-family: Myriad Pro;
font-size: 18px;
font-weight: bold;
margin-top: 3%;
line-height: 29px;
color: #4f4f50;
}
.pri-to-section .label{  
display: block;
margin-left: 30%;
line-height: 50px;
}
.right_frame_c_pri{
background: #dfebf1;
height: 100%;
text-align: center;
font-weight: bold;
line-height: 24px;
}
.pri-to-section  .pi{
font-size: 100px;
}
.right_frame_c_pri p{    
display: table-cell;
vertical-align: middle;
padding: 0px 10px;
}
.right_frame_c_pri{
display: table;
}
.Middle_frame_pri .row{
margin-top: 3%;
margin-bottom: 3%;
}
.bottom_framepri_frame{   
background: #faf8e3;
text-align: center;
padding: 20px 20px;
margin-bottom: 3%;
margin-top: 5%;
}
.sani_left_top{
background: #9bb0cd;
padding: 20px 20px;
margin-top: 10%;
}
.sani-top-banner{ 
margin-bottom: 2%;
}
.clan_midd_frame{                
padding: 20px 20px;
}
.clan_midd_frame.fi {
background: #e7e0d6;
}
.clan_midd_frame.tw{
background: #c2dacd;
}
.clan_midd_frame.three{
background: #e4c8a3;
}
.clan_midd_frame.four{background: #c9b1b1;    margin-top: 10%;}
.row_sani_top{margin-bottom: 5%;}
.row_mi_text{margin-bottom: 5%;}
.row_cle_img{margin-bottom: 5%;}
.top_head_hair {    margin-top: 3%; text-align: center; margin-bottom: 5%;}
.haircarenewpage .top_head_hair-heading img{width: 210px;margin: 0 auto; margin-top: 20px;}
.mencarenewpage .top_head_men-heading img{width: 310px;margin: 0 auto; margin-top: 20px;}
.haircarenewpage p{font-size: 18px; line-height:32px;}
.mencarenewpage p{font-size: 17px; line-height:29px;}
.haircarenewpage p::first-letter{font-weight: bold; font-size: 25px;}
.mencarenewpage p::first-letter{font-weight: bold; font-size: 25px;}
.mencarerecentyear{    margin: 0 auto;
    width: 90%;}
    .mencarerecentyear2{    margin: 0 auto;
        width: 97%;  
        margin-top: 20px;
    }
    .mencarerecentyear2 .col-md-8{ padding-left: 0px;}
    .mencarerecentyear2 .col-md-8 p{
        background: #f4e2ce;
        margin-top: 115px;
        width: 90%;
        line-height: 32px;
        padding: 40px 20px;
    }
    .mencarerecentyear2 .col-md-4{ padding-right: 0px;  }
    .mencareskinis{margin-top: 60px;}
    .mencareskinis p{margin-top: 50px;}
    .mencareskinis img{margin-top: -160px;}
    .mencarerecentyear p{margin-top: 80px;}
.top_head_men-heading p{    
        margin-bottom: 20px;
        margin-top: 20px;
    }
.right_hair_cont_pagenew h3{font-weight: bold;
    font-size: 34px;
    line-height: 48px;
    margin-top: 100px;}
.right_hair_cont_pagenew h3 .right_hair_cont_pagenewgh{color: #21265e;}
.right_hair_cont_pagenew h3 .right_hair_cont_pagenewgh2{color: #217d3e;}
.top_head_hair-heading p{    width: 80%;
    margin: 0px auto;margin-top: 20px; }
    .middle_bott_hair{
        margin-top: 30px;
        margin-bottom: 20px;
        }
        .haircarelast-line{
            background: url(../src/lab/HairCare7.jpg);
            padding: 120px 0px;
            background-size: cover;
            background-position: left;
            margin-top: 50px;
           }
        .haircarelast-line p{
                color: #fff;
                padding-left: 270px;
                line-height: 40px;
                font-size: 20px;
            }
        .manustoryhaircarepage{
            background: url(../src/lab/HairCare6.jpg);
            padding: 40px 0px;
            background-attachment: fixed;
            background-size: cover;
        }  
        .manustoryhaircarepage h4{    
            padding: 15px 10px;
            margin-top: 30px;
            background: #fff;
            text-align: center;
            font-weight: bold;
            display: inline-block; 
            color: #7c6754; 
            }
        .manustoryhaircarepage ul{
            padding: 0px;
            list-style-type: none;
            display: inline-block;
            margin-left: 10px;
            margin-top: 40px;
            }
            .manustoryhaircarepage .manustoryhaircarepage-right ul{
                float: right;
                margin-right: 75px;
            }
        .manustoryhaircarepage ul li{
            background: #fffc;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            padding: 7px 10px;
            width: 280px;
        }
        .mencareproductlist-bottom.heading-img{background: #fff;}
        .haircarenewpage p .high{font-weight: 500;
            font-family: BRLNSR;}
        .immenseframemencare{
            width: 90%;
            margin: 0 auto;
            margin-top: 30px;
            margin-bottom: 30px;
            text-align: center;
        }
        .mencaremiddle-border{
            margin-top: 100px;
            border-left: 2px solid #0000008f;
            height: 75%;   
        }
        .mencareproductlist-bottom.heading-img img{    
                width: 500px;
                margin: 0 auto;
                margin-bottom: 10px;
                margin-top: 30px;  
            }
        .mencareproductlist-bottom.lastp p{margin-top: 100px;}
        .mencareproductlist-bottom.lastp .col-md-7{padding-left: 0px;}
        .mencareproductlist-bottom.lastp{background: #fff;margin-top: 40px;}
        .mencareproductlist-bottom ul{
            padding: 0px;
            list-style-type: none;
            margin-top: 60px;
        }
        .mencareproductlist-bottom ul li{
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 15px;
            text-align: center;
        }
        .mencareproductlist-bottom{background: #e2e3e5;}
        .mencareproductlist-bottom .row{margin: 0px;}
        .mencareproductlist-bottom .col-md-5{padding-left: 0px;}
            .mencarenewpage p .high{font-weight: 500;
                font-family: BRLNSR;}
    .middle_bott_hair .col-md-12covid{margin-top: 15px;}
    .bottom_2nd_sect_hair .bottom_2nd_sect_hair-leftnew{margin-top: 90px;}
    .bottom_2nd_sect_hair .bottom_2nd_sect_hair-leftnew p{line-height: 40px;}
    .bottom_2nd_sect_hair{margin-bottom: 5px;}
    .top_head_hair-heading p span{    display: block;
        padding-left: 35%;}
.right_hair_cont_page{     
width: 90%;
background: #e8e8e8;
padding: 20px;
line-height: 26px;
margin-top: 10%;
}
.row.middle_bott_hair{ margin-top: 5%; margin-bottom: 5%; }
.bottom_2nd_sect_hair-right{background: #4d4d4d;
color: #fff;
padding: 21px 20px;
line-height: 26px;}
.bottom_2nd_sect_hair-left{padding: 21px 20px;
    line-height: 26px;}
.row.bottom_2nd_sect_hair{margin-bottom: 5%;}
.rech_now_cont.new{
    margin-bottom: 5%;
}
.make-top_page{    background: #e8dbec;
    padding: 15px;
    margin-top: 2%;}
.make_new_imgf img{    width: 750px;
    margin: 0 auto;
    display: table;}
.b_heading_bo{    padding: 10px 10px;
    background: #3f546e;
    }
    .makeup-new-pageAd .b_heading_bo .container{width:100%; max-width: 100%;padding: 0px;}
    .makeup-new-pageAd .b_heading_bo .container .col-md-4{padding-right: 0px; padding-left: 0px;}  
    .makeup-new-pageAd .b_heading_bo .container p{line-height: 40px;}
    .makeup-new-pageAd .b_heading_bo{padding: 0px;}
    .makeup-new-pageAd .b_heading_bo .container .row{margin: 0px;}
    .b_heading_bo.two{   background: #976c78; }
    .b_heading_bo.three{   background: #828065; }
    .b_heading_bo video{
        width: 100%;
        display: block;
    }
    .b_heading_bo_left{    margin-top: 90px; padding-left: 5%;}
    .b_heading_bo.two .b_heading_bo_left{   
        margin-top: 10  0px;
        padding-left: 10%;
        width: 100%;
    }
    .makeup-new-pageAd .b_heading_bo{    width: 96%; margin-left: 4%;}
    .makeup-new-pageAd .b_heading_bo.two{    width: 90%;     margin-left: 0%;}
    .makeup-new-pageAd .b_heading_bo.three{    width: 86%; margin-left: 14%;}
    .b_heading_bo.three .b_heading_bo_left{   
        margin-top: 150px;
        
    }
    
    .b_heading_bo_left p{color: #fff;width: 90%; font-size: 17px;}

.midd_makenew_left{
    background: #e8dbec;
    padding: 20px;
    height: 100%;
}
    .midd_makenew_right{
        background: #e6f5f8;
        padding: 20px;
        height: 100%;
    }
    .row_frem_makenew{ margin-top: 3%; margin-bottom: 3%; }
    .bottom_midd_2nd_makenew{    padding: 20px;
        background: #d7ebd2;
        border: 1px solid #000;
        margin-bottom: 3%;}
        .right_img_last_bo_makeupnew {   
            background: #f2d4d2;
            border: 1px solid #000;
            padding: 20px;
            height: 100%;
        }
        .left_img_last_bo_makeupnew_row{margin-bottom:5%;}
        .branding_new_pae-left{padding: 20px; width: 90%; margin-top: 25px;}
            .branding_new_pae-left h3{    
                font-weight: bold;
                font-size: 32px;
                margin-bottom: 20px;
            }
            .branding_new_pae-right{    margin-top: 10%;}
            .branding_new_pae-right p{    background: #fbdde7;
                padding: 5px 10px;
            }
            .bodycareNewpageVideo-rightvideo video{width: 100%;}
            .row_branding_top .col-md-12.bdex{    
                width: 85%;
                max-width: 85%;
                margin: 0 auto;
                text-align: center;    
            }
            .left-content-frame p{ text-align: center; margin-top: 100px;    font-weight: bold;
                line-height: 40px !important;
            }
            p.Bring-center-margin{   
                    width: 50%;
                    margin: 0 auto;
                    text-align: center;
                    margin-top: 20px;
                }
            .branding_new_page p::first-letter{font-weight: bold; font-size: 22px;}
            .bottomreflesh-brand{margin-top: 50px; margin-bottom: 50px;}
            .bottomreflesh-brand-first img{margin-left: 120px;}
            .bottomreflesh-brand-first.nd img{margin-left: 0px;margin-top: -60px;}
            .bottomreflesh-brand-first.nd .bottomreflesh-brand-first-right{    left: 130px;
                    position: relative;
                    z-index: 99;
                }
           
            .bottomreflesh-brand-first-right{    background: #ffffffc2; padding: 20px; margin-top: 45px;}
            .bingyour-branding p{    text-align: center; margin-top: -50px;}
            .branding-innvotivemain .fisstsect .col-md-5{background: #b8b6d9; padding: 70px;}
            .branding-innvotivemain .fisstsect.second .col-md-5{background: #ebc6de;}
            .branding-innvotivemain .fisstsect.second .col-md-7{background: #c6e7e3;}
            .branding-innvotivemain .fisstsect.second .left-content-frame p{margin-top: 200px; }
            .branding-innvotivemain .container{width: 100%; max-width: 100%;}
            .branding-innvotivemain{background: #e6d1c7;}
        .row_branding_top{    margin-top: 3%;
            margin-bottom: 100px; position: relative;}
            .branding_right_end h3{    margin-bottom: 5%;
                text-align: center;
                margin-top: 6%;
            }
            .bodycarenewBG{}
            .makeupBG{}
            .mencareBG .top_head_men-heading{background: #fff;}
            .mencareBG{background: url(../src/lab/MencareBG.png);}
            .HotelBG{background: url(../src/lab/HotelBG.png);}   
           
            .bodycarenewBG .container{background: #fff;}
            .bodycarenewBG  .bodycare-industry-new .container{    display: table;}
            .row_branding_top video{position: relative; z-index: 999;}
            .row_branding_top img{position: relative; z-index: 999;}
            
            .brand-font-number{    
                position: absolute;
                top: 0px;
                right: -55px;
            }
            .brand-font-number.one{
                top: 0px;
                right: -60px;
            }
            .brand-font-number.tw{
                top: 0px;
                right: -60px;
            }
            
            .brand-font-number p{color: #b0b0b0; font-size: 250px !important;opacity: .4;}
            .brand-font-number p span{    visibility: hidden;}
            .branding_right_end p{
                line-height: 25px;
                padding: 20px;
                background: #cfe8d5;}
                .creative_branding{}
                .creative_branding h3{}
                .creative_branding p{    text-align: left;
                    background: #d5daee;
                    padding: 20px;
                } 
                .creative_branding{text-align: center;}
                .row_branding_three{
                    margin-top: 5%;
                }
                .image_midd_brand_ing img{    margin: 0 auto;
                    width: 70%;}

.col-md-12_branding_four{background: url(https://ik.imagekit.io/deepak/middle_pack_qwLXZTuAj.jpg);
padding: 50px;
margin-bottom: 5%;}
.row_branding_four_middle{ padding: 20px; background: #dcdcdcab; font-weight: 500;}
.top_main_page_ctstom1 p.decade span{font-size: 44px;
font-weight: bold;}
.top_main_page_ctstom1 p.decade{font-size: 16px;}
.top_main_page_ctstom1 h2{color: #231f1e;    font-family: LucidaHandwritingItalic;}
.top_main_page_ctstom1 h5{line-height: 35px;
margin-top: 25px;
color: #000;
font-size: 24px;
background: #c0e0b9;
padding: 5px 20px;
display: inline-block;
}
.top_main_page_ctstom1 h5 span{display: block;}
.top_main_page_ctstom1{    text-align: center;
margin-top: 3%;
margin-bottom: 4%;}
.top_main_page_ctstom2{
margin-bottom: 5%;
}
.custom-1mainpage.new span.bold_new{font-weight: 500;
font-family: BRLNSR;
font-size: 22px;}
.custom-1mainpage.new .text.under_stand span.bold_new{font-size: 20px;}
.CustomFor_cutat2{    position: relative;  } 
.CustomFor_cutat2 img{    width: 55%;
margin: 0 auto;
margin-top: 4%;
}
.CustomFor_cutat1{text-align: center;}
.CustomFor_cutat1 h2{    color: #1c1548;}
.CustomFor_cutat2 p{left: 10%;
background: #fcf8f9d1;
position: absolute;
top: 30%;
width: 80%;
padding: 20px;}
.bespoke_main_middle{background: #e6e4e5;}
.bespoke_main_middle{   
padding: 20px;
margin-top: 3%;
}
.bespoke_main_row{    margin-top: 2%; margin-bottom: 3%;}   
.bespoke_main_row h3{    color: #7d6e6b;}
.bespoke_main_row .borken-boder{border-bottom: 0.1px solid #7d6e6b8c;
font-size: 4px;
width: 35%;}
.bespoke_main_middle.n{background: #fff;padding: 0px 0px;}
.bespoke_main_row.last{margin-bottom: 5%;}
.custom-2-heading_main_page{margin-top:3%;}
.custom-2-heading_main_page h2{    color: #1c1548;
text-align: center;
font-size: 28px;}
.bespoke_main_middle.new{    background: #ede2cc}
.bespoke_main_middle.new2{background: #deedcc;}
.bespoke_main_middle.new3{background: #eff8f5; width: 95%; margin: 0 auto; margin-top: 4%;}
.product_fl_main_middle{position: relative;}
.product_fl_main_middle h4{
position: relative; 
background: #f0f1ece8;
width: 100%;
text-align: center;
font-size: 15px;
padding: 0px 0px;
letter-spacing: 1px;
height: 57px;
font-weight: 700;
line-height: 57px;
margin-bottom: 0px;

}
.Last_buttom_filecus{position: relative; overflow: hidden;}
.g-comming-elt img{ margin: 0 auto; width: 65%; }
.g-comming-elt{bottom: 30px; position: absolute;}
.Last_buttom_filecus{    width: 100%;
margin: 0 auto;
margin-bottom: 6%;}
.Last_buttom_filecus video{width: 100%;}
.make-top-banner video{width:100.1%;}   
.top-video_sect{width: 100%; overflow: hidden;}
.product_fl_main_middle.four h4{     line-height: initial;}
.product_fl_main_middle.four h4 span{line-height: 32px;}

.product_fl_main_middle h4 span{font-size: 30px;}

.product_fl_main_middle p{ text-align: center; margin-top:2%; }
.product_fl_main{    margin-top: 5%;}
.pro-mu_all_bottom h2{
color: #1d1649;
text-align: center;
font-size: 27px;
margin-top: 5%;
margin-bottom: 2%;
}
.pro-mu_all_bottom{
margin-bottom: 5%;
} 
.row.bespoke_main_bottom{  margin: 0 auto;
margin-top: 5%;
width: 85%;}
.bespoke_main_bottom_led{}
.bespoke_main_bottom_led h4{    text-align: center;
font-size: 20px;
color: #7c6e6b;
margin-bottom: 5%;
height: 45px;
display: inline-table;
}
.bespoke_main_bottom_led.Ne img{margin: 0 auto;
width: 70%;
/* display: table; */
margin-bottom: 3%;}
.bespoke_main_bottom_led img{    margin-bottom: 3%;}
.bespoke_main_bottom_led p{    background: #f0ddcc;
padding: 10px;
font-weight: 500; }
.bespoke_main_bottom_led.Ne p{    background: #e6e4e5;}
.bottom_frame_cuts-3{    margin-top: 5%;}
.bottom_frame_cuts-3 h4{margin-bottom:4%; text-align: center;}
.bottom_frame_cuts-3 .bottom_frame_cuts-3_cont{background: #c6e2f7;
padding: 20px;    margin-bottom: 5%;
}
.bespoke_main_bottom_led.one  p{background: #f0ddcc;}
.bespoke_main_bottom_led.two  p{background: #dff0f8;}
.bespoke_main_bottom_led.two.Ne  p{background: #f1eedf;}

.bespoke_main_bottom_led.treee  p{background: #fdf7f9;}
.bespoke_main_bottom_led.four  p{background: #cbc0ce;}
.row.bespoke_main_middle.one{background: #f7e6ee;}
.row.bespoke_main_middle.two{background: #e3e8d1;}
.row.bespoke_main_middle.three{background: #f9f5ec;}
.row.bespoke_main_middle.four{background: #f9f5ec;}
.row.bespoke_main_middle.five{background: #dad8ed;}
.bespoke_main_bottom_led.Ne h4 span{    display: block; }
.bespoke_main_bottom_led.Ne h4 span b{left: 87px;
position: absolute;
font-size: 38px;
top: -5px;}
.bespoke_main_bottom_led.Ne h4 span b.guidence{left: 76px;
top: -6px;}
.bespoke_main_bottom_led.Ne h4 span b.bespoke{left: 78px;}
.bespoke_main_bottom_led.Ne h4 span b.research{    left: 98px;  }
.bespoke_main_bottom_led.Ne h4 span b.prototype{    left: 117px;  }
.bespoke_main_bottom_led.Ne h4 span b.stability{   left: 104px; top: -5px;  }

.bespoke_main_bottom_led.Ne{text-align: center;}
.pro-mu_all_bottom .animated.slideInUp{    background: #e7e7f1;
padding: 20px;
}
.Image_frame_bakuchiol{}
.Image_frame_bakuchiol h1{text-align: center;
font-size: 32px;
margin-top: 3%;
margin-bottom: 3%;}
.BakuchiolBlog-page_cont p{
line-height: 26px;
font-size: 15px;
}
.Image_frame_bakuchiol img{margin-bottom: 1%;}
.bottom_cont_BakuchiolBlog{    margin-top: 2%;
margin-bottom: 3%;
}
.bottom_cont_BakuchiolBlog h4{font-size: 21px;font-weight: bold;}
.BakuchiolBlog-page_cont{margin-bottom: 5%;}
.chmicalBlog-page.BakuchiolBlog-page_cont h1 span{display: block;
font-size: 23px;
margin-top: 2px;}
.bottom_cont_BakuchiolBlog h5{    margin-bottom: 2%; font-size: 18px;}
.chmicalBlog-page .bottom_cont_BakuchiolBlog h4{    color: #5a4f53; font-size: 19px;}
.faceoilBlog-page .Image_frame_bakuchiol img{width: 65%;
margin: 0 auto;
margin-bottom: 1%;}
.bottom_cont_BakuchiolBlog .center_al{text-align: center;
    
    font-weight: normal;}
.text-center{text-align: center;}
.bottom_cont_BakuchiolBlog ul{padding: 0px 15px;}
.bottom_cont_BakuchiolBlog ul li{ margin-bottom: 7px;}
.GreesyhairBlog-page .bottom_cont_BakuchiolBlog h4{background: #e8edf0;
display: inline-block;
padding: 10px;}
.bottom_cont_BakuchiolBlog ul li b{ font-style: italic; }
.skincare-trendsBlog-page .bottom_cont_BakuchiolBlog h3{background: #c66c30;
color: #fff;
text-transform: uppercase;
text-align: center;
padding: 7px 1px;
font-size: 18px;
width: 80%;
margin: 0 auto;
margin-bottom: 3%;
margin-top: 3%;}
.BakuchiolBlog-page_cont .container.new{max-width: 900px;}
.BakuchiolBlog-page_cont .container{max-width: 1100px;}
.tretback .bottom_cont_BakuchiolBlog h3{background: #543932;
color: #fff;
text-transform: uppercase;
text-align: center;
padding: 7px 1px;
font-size: 18px; 
width: 80%;
margin: 3% auto;}
.Pimples-page .Image_frame_bakuchiol img{    width: 300px;
margin: 0 auto;    margin-bottom: 3%;}
.Pimples-page .bottom_cont_BakuchiolBlog img{    width: auto;
margin: 0 auto;}
.Pimples-page .bottom_cont_BakuchiolBlog ul li::marker  {content: "\002A";font-weight: bold;}
.Pimples-page .bottom_cont_BakuchiolBlog ul li span{    padding-left: 10px;
    display: block;}
    .Pimples-page .bottom_cont_BakuchiolBlog ul li{margin-bottom: 15px;}
.Pimples-page .bottom_cont_BakuchiolBlog ul{padding: 5px 5px;}
.Pimples-page.BakuchiolBlog-page_cont.tretback.treatsunrn .Image_frame_bakuchiol img{width: 65%;}
.blush .Image_frame_bakuchiol h1{    color: #452c27;}
.BakuchiolBlog-page.BakuchiolBlog-page_cont.blush{margin-top: 2%;}
.Bodyacne-happ h1{text-align: center; font-size: 30px; margin-top: 2%;}
.dark-underarms-treatment h1{    background: #e7e6f4;
    display: inline-block;
    padding: 5px 40px;
color: #666b8b;}
    .dark-underarms-treatment .Image_frame_bakuchiol{text-align: center;}
.dark-underarms-treatment ul{}
.dark-underarms-treatment ul li{margin-bottom: 2%;}
.dark-underarms-treatment .bottom_cont_BakuchiolBlog:nth-child(2) li{list-style-type: circle;}
.dark-underarms-treatment h4{color: #4b4c50;
    font-weight: normal;}
.dark-underarms-treatment.Ecul h4{background: #f2dcc7;
display: inline-block;
padding: 7px 30px;
margin-top: 2%;} 
.dark-underarms-treatment.Ecul .bottom_cont_BakuchiolBlogn.cen{text-align: center;}
.dark-underarms-treatment.Ecul .bottom_cont_BakuchiolBlog ul li{list-style-type: disc;} 
.dark-underarms-treatment.Ecul h1{    background: #fff;
color: #314931;} 
.CustomFor_cutat1.new{}
.CustomFor_cutat1.new h2{
    background: #fa9440;
    color: #fff;
    font-weight: normal;
    font-size: 25px;
    display: inline-block;
    padding: 5px 20px;
    letter-spacing: 5px;
    margin-bottom: 20px;
}
.CustomFor_cutat2-new p{font-size: 16px; line-height: 28px;} 
.CustomFor_cutat2-new p span{font-size: 44px; font-weight: bold;} 
.bespoke_main_middlennn-rigt p span{font-size: 44px; font-size: bold;} 
.how-it-word_custfor h2{
background: #e8bb9a;
color: #000;
font-weight: normal;
font-size: 25px;
display: inline-block;
padding: 5px 20px;
letter-spacing: 5px;
margin-bottom: 3%;
}  
.how-it-word_custfor {text-align: center;}
.bespoke_main_bottom_led.Ne h4{
width: 100%; 
margin: 0 auto;
height: auto;
display: inline-block;
background: #e5dad4;
color: #000;
margin-bottom: 5px;  
padding: 5px 0px;
font-family: MYRIADPRO-REGULAR;
letter-spacing: 1px;
font-size: 21px;

}
.bespoke_main_row.long-to .text-center .img-responsive-development{ margin-bottom: 2%; width: 530px;}
.bespoke_main_bottom_led.Ne .formu-middle-fram-img{background: #e5dad4;}
.bespoke_main_bottom_led.Ne.new2 .formu-middle-fram-img{background: #f7e1b8;}
.bespoke_main_bottom_led.Ne.new2 h4{background: #f7e1b8;}
.bespoke_main_bottom_led.Ne.new3 .formu-middle-fram-img{background: #fbcbbd;}
.bespoke_main_bottom_led.Ne.new3 h4{background: #fbcbbd;}
.bespoke_main_bottom_led.Ne.new4 .formu-middle-fram-img{background: #f1abcd;}
.bespoke_main_bottom_led.Ne.new4 h4{background: #f1abcd;}
.bespoke_main_bottom_led.Ne.new5 .formu-middle-fram-img{background: #fbeeb7;}
.bespoke_main_bottom_led.Ne.new5 h4{background: #fbeeb7;}
.bespoke_main_bottom_led.Ne.new6 .formu-middle-fram-img{background: #aee0f7;}
.bespoke_main_bottom_led.Ne.new6 h4{background: #aee0f7;}
.how-it-word_custfor .bespoke_main_bottom_led.Ne{ margin-bottom: 30px; }
.bespoke_main_row.long-to h3{
background: #ebedd5;
display: inline-block;
font-weight: normal;
padding: 5px 20px;
letter-spacing: 3px;
font-size: 23px;
margin-bottom: 3%;
color: #000;
font-family: MYRIADPRO-REGULAR;
}   
.bespoke_main_row.long-to h3 span{   font-size: 45px; text-shadow: 5px 5px 5px #fff;}
.padding{ padding-left: 0px !important; padding-right: 0px !important; }
.bespoke_main_middlennn-rigt{
background: #ebedd5;
padding: 10px 10px;
margin-top: 10%;
}
.bespoke_main_middlennn-rigt p{    font-size: 16px;
font-weight: 400;}
.custom-3new_page fieldset .img-responsive-osmetic{width: 370px;}
.custom-3new_page fieldset {  
text-align: center; 
display: block;
margin-inline-start: 2px;
padding: inherit !important;
margin-inline-end: 2px;
padding-block-start: 0.35em;
padding-inline-start: 0.75em;
padding-inline-end: 0.75em;
padding-block-end: 0.625em;
min-inline-size: min-content;
border-width: 0px !important;
border-style: groove !important;
border-color: #cfb5d0 !important;
border-image: initial !important;
margin: 0 auto;
width: 90%;
margin-top: 5%;
}
.how-it-word_custfor img.img-responsive-work{width: 380px; margin-bottom: 5%;}
.CustomFor_cutat1.new .img-responsive-brand{margin-bottom: 3%;    width: 360px;}
.custom-3new_page legend { 
display: block;
padding-inline-start: 2px;
padding-inline-end: 2px;
border-width: initial;
border-style: none;
border-color: initial;
border-image: initial;
width: auto;
max-width: initial !important;
text-align: center !important;
background: #cfb5d0;
background: #cfb5d0;
letter-spacing: 6px;
}
.custom-3new_page fieldset p{    margin: 15px auto;
font-size: 16px;
text-align: center;
width: 85%;}
.CustomFor_cutat2-new{    margin: 0 auto;
width: 90%;}
.product_fl_main-new-bottom_heading .img-responsive-client{    width: 320px;}
.custom-3new_page fieldset p span{font-size: 44px; font-weight: bold;}  
.right-bespoke_main_middle p span{font-size: 44px; font-weight: bold;}
.product_plus h4{background: #feeab7;display: inline-block;letter-spacing: 6px;font-weight: normal;padding: 5px 10px;}
.product_plus h4 span{    font-size: 32px; font-weight: bold;}
.product_plus-new{text-align: center;}  
.product_plus h2{ margin-top: 2.5%; color: #575151; font-size: 28px;}
.right-bespoke_main_middle p{ font-size: 17px; font-weight: 400; margin-top: 11%; }
.product_fl_main_middle p{background: #ebeef5;
font-size: 16px;
padding: 0% 3% 0% 3%;
margin-top: 0px;
height: 385px;
display: flex;
text-align: center;  
{/*align-items: center;*/}  
}
.product_fl_main_middle .flip-card-back p span.blod{font-size: 35px;}
.custom-1mainpage.new .flip-card-back p span.bold_new{font-size: 20px;}
.top-back-banner_all{ background: #e3e6ed; padding-top: 1px; }


.flip-card {

width: 100%;
height: auto;
perspective: 1000px;
}

.flip-card-inner {
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.6s;
transform-style: preserve-3d;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.flip-card-front {
background: #f4efeb;
cursor: pointer;
}

.flip-card-back {
background: #f4efeb;
transform: rotateY(180deg);
}


.product_fl_main.Mobile{display: none;}
.product_fl_main.Mobile .product_fl_main_middle{margin-bottom: 20px;}
.product_fl_main.Mobile .flip-card-back{transform: initial;top: 0px;}   
.product_fl_main.Mobile .flip-card-back{transform: initial;background: transparent;}
.product_fl_main.Mobile .flip-card-front, .flip-card-back {position: relative;} 
.product_fl_main.Mobile .flip-card:hover .flip-card-inner { transform: initial; }  
.product_fl_main.Mobile .flip-card-inner{box-shadow: initial;}
.product_fl_main.Mobile .animated {height: 100%;}
.product_fl_main.desktop{display: initial;}
.product_fl_main.Mobile .flip-card-front--- img{    float: right;
width: 47%;
margin-right: 10px;
margin-top: 15px;
}
.product_fl_main.Mobile .flip-card-front---  p{display: inherit; text-align: left;padding-bottom: 15px;}
.product_fl_main.Mobile h4{    background: #ebeef5;}

.product_fl_main.Mobile .product_fl_main_middle.tww h4{    background: #f6ebf3;}   
.product_fl_main.Mobile .product_fl_main_middle.thee h4{    background: #f4efeb;}
.product_fl_main.Mobile .product_fl_main_middle.four h4{    background: #ebedd5;}
.Last_buttom_filecus.Mobile{display: none;}





.custom-1mainpage.new .product_fl_main.Mobile span.bold_new{    font-weight: bold;
font-family: 'MYRIADPRO-REGULAR';
font-size: 16px;}
.product_fl_main.Mobile p span.blod{    font-weight: normal;
font-size: 30px;
line-height: 36px;}









.bespoke_main_bottom_led.Ne .overlay-bottom-slide p span.bold_new{display: initial;
margin-top: inherit;}
.product_fl_main_middle p span.blod{    font-weight: normal; font-size: 44px;}
.product_fl_main_middle p span{
font-weight: bold;
display: contents;
}
.product_fl_main_middle.four p{background: #ebedd5;}
.product_fl_main_middle.three p{background: #f4efeb;}
.product_fl_main_middle.two p{background: #f6ebf3;}
.product_fl_main-new-bottom_heading {text-align: center;}
.product_fl_main-new-bottom_heading h4{
background: #e2deef;
display: inline-block;
letter-spacing: 6px;
font-weight: normal;
padding: 5px 10px;
}
.product_fl_main-new-bottom_heading{    text-align: center;
margin-top: 40px;  
margin-bottom: 3%;}
.product_fl_main_middle-client p{    background: #f9c3da;
font-size: 16px;
padding: 10px 10px;}
.bespoke_main_bottom_led.Ne{
    position: relative;
}
.product_fl_main_middle-client.two p{ background: #e5d7b2;}
.product_fl_main_middle-client.thre p{ background: #dfa987;}
.product_fl_main-new-bottom-client{    margin-bottom: 5%;}
.bespoke_main_bottom_led.Ne .formu-middle-fram-img{position: relative;}
.formu-middle-fram-img-hover{ align-items: center; 
justify-content: center; display: none;  position: absolute; top: 0px; background: #e5dad4f2; cursor: pointer;height: 100%;}
.bespoke_main_bottom_led.Ne .formu-middle-fram-img-hover p{background: transparent;padding: 10px 10px 0px 10px;
margin: 0px;}
.bespoke_main_bottom_led.Ne .formu-middle-fram-img:hover .formu-middle-fram-img-hover{display: flex;}
.bespoke_main_bottom_led.Ne.new2 .overlay-bottom-slide{background: #f7e1b8;}
.bespoke_main_bottom_led.Ne.new3 .overlay-bottom-slide{background: #fbcbbd;}
.bespoke_main_bottom_led.Ne.new4 .overlay-bottom-slide{background: #f1abcd;}
.bespoke_main_bottom_led.Ne.new5 .overlay-bottom-slide{background: #fbeeb7;}
.bespoke_main_bottom_led.Ne.new6 .overlay-bottom-slide{background: #aee0f7;}
.bespoke_main_bottom_led.Ne .formu-middle-fram-img-hover p span{display: block;margin-top: 10px;}
.bespoke_main_bottom_led.Ne .overlay-bottom-slide p{background-color: transparent !important; font-weight: normal;line-height: 22px;
font-size: 14px;}
.bespoke_main_bottom_led.Ne .overlay-bottom-slide p span{display: block;margin-top: 5px;}
.overlay-bottom-slide {
position: absolute;     
opacity:0;
bottom:0;
right:0;      
top:100%;
height:0;
width:100%;
overflow: hidden;
transition: .8s ease;
background-color: #e5dad4;
}
.formu-middle-fram-img:hover .overlay-bottom-slide
{
top: 0;
height: 100%;
opacity:5;
}
.text{          
color: #000;
font-size: 14px;
font-weight: bold;
padding: 10px 15px;  
text-align: center;      
position:absolute;
top:50%;
left:50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
width:100%;
background-color: #e5dad4 !important;
}
.top_main_page_ctstom1 .img-responsive-green{width: 160px; margin-top: 1%;}






.top-banner-howit4{    margin-bottom: 40px;}
.middle_main_pre_frame{
text-align: center;
margin-top: 30px;
}
.main_middle_platic-right img{ width: 85%; float: right; }
.blog-detail-page{    margin-top: 25px;
margin-bottom: 25px;}
.Heading-blog h1{border-bottom: 1px solid #000;
font-size: 28px;

display: inline-block;}
.blog-con-frame p{    
font-size: 16px;
}
.place_end{text-align: center;
line-height: 28px;}
.main_middle_platic{    margin-bottom: 30px;
margin-top: 20px;}
.right_bott_mi_main{margin-bottom: 30px;
margin-top: 20px;}
.blog-con-frame p span{font-weight: bold;}
.Heading-blog {text-align: center;    margin-bottom: 30px;}
.post-image img {
width: 300px;
}
.clr{clear: both;
height: 0;
line-height: 0;
display: block;
float: none;
padding: 0;
margin: 0;
border: none;}
.post-image {
float: left;
margin-right: 20px;
}
.itemListCategory {
background: none;
border: 0px;
border-bottom: 1px solid #ddd!important;
border-radius: 0px;
margin: 0 0 24px 0;
padding: 8px;
}
.blog-img-frame{
border: 1px solid rgba(204, 204, 204, 0.32);
margin-bottom: 5%;
padding: 0px;
}
.blog_bottom_frame{padding: 10px 10px;}
.blog_bottom_frame h3{font-size: 22px;
line-height: 28px;}
.itemListCategory h1{ font-size: 30px; margin: 0px;}
.footer_pre_frame{    text-align: center;}
.footer_pre_frame img{width: 450px;
margin-bottom: 30px;
margin-top: 30px;}
.middle_main_pre_frame h2{    font-size: 30px;
font-family: Raleway-ExtraBoldr;}
.middle_main_pre_frame p{font-family: 'Raleway', sans-serif;
font-size: 16px;}
.middle_main_pre_frame .bordern{
border: 1px solid;
width: 140px;
margin: 0 auto;
border-color: #000;
margin-bottom: 20px;
} 

.packaging_main_frame h4{font-family: Raleway-ExtraBoldr;    font-size: 24px;}
.packaging_main_frame p {
font-family: 'Raleway', sans-serif;
font-size: 17px;
line-height: 28px;
}
.packaging_main_frame .only_use_frame_img{ 
width: 100%;
display: inline-block;
}
.row.brand-idea{
margin-top: 60px;
}
.brand_gap_frame{    border: 1px solid #00000094;
margin: 0 auto;
width: 70%;
padding: 20px;
margin-top: 50px;
margin-bottom: 50px;
}
.row.brand-idea .col-md-7{    text-align: right;padding-top: 70px;}
.row.brand-idea p{ line-height: 30px;}
.packaging_main_frame .brand_gap_frame p{
text-align: center;
line-height: 25px;
margin-top: 3%;
margin-bottom: 5%;
}
.Brand_Experience h4{text-align: center;}
.main_design_frame_right{ margin-top: 100px; }
.packaging_main_frame .only_use_frame_img img{float: left; width: 50%;}
.packaging_main_frame .brand_express_all .col-md-8{text-align: right; padding-top: 5%;}
.packaging_main_frame .brand_express_all{margin-top: 25px; margin-bottom: 25px;}
.packaging_main_frame .only_use_frame p{margin-top: 20px;
margin-bottom: 20px;}
.packaging_main_frame .bottom_top_b_frame{    width: 90%;
margin: 0 auto;}
.innvoo{    text-align: center;
margin-top: 40px;}
.innvoo p{margin-bottom: 40px;
font-family: Raleway-ExtraBoldr;
font-size: 14px;
margin-top: 15px;
}
.start_design_page{border: 1px solid #0000006b;
text-align: center;
padding: 40px;
width: 70%;
margin: 0 auto;
margin-top: 40px;}
.innvoo img{    width: 240px;}
.packaging_main_frame .bottom_top_b_frame .col-md-6:last-child img{ width:80%; float:right; }
.packaging_main_frame .bottom_top_b_frame .col-md-6:first-child img{    margin-top: 30px;}
.packaging_main_frame .only_use_frame{     margin-top: 50px;
width: 70%;
margin: 0 auto;}
.packaging_main_frame .only_use_frame .col-md-4 img{
margin-left: 30%;
width: 70%;
} 
.packaging_main_frame .main_design_frame_right .img-responsive_al{
display: table;
margin: 0 auto;
width: 54%;
margin-top: 80px;
}
.CREATIVE_SERVICES img{  
width: 240px;
margin-left: 40px;
margin-top: 25px;
}
.packaging_main_frame .brand_express_all img{
width: 200px;
margin-left: 60px;
}
.Brand_Experience{
margin: 0 auto;
padding: 30px;
border: 2px solid #00000047;
text-align: left;
width: 80%;
}
.middle_banner_img{
margin: 0 auto;
width: 60%;
border: 2px dashed #0000006e;
padding: 15px;
margin-top: 50px;
margin-bottom: 50px;}
.top_cont.VISUAL_ID{    margin-top: 100px;
text-align: right;}
.top_cont.VISUAL_ID img{
width: 240px;
margin-right: 80px;
margin-top: 40px;
}
.top_cont.BRAND_INTERACTION{    margin-top: 50px;}
.top_cont.AUDIO_IDENTITY img{width: 250px;}
.top_cont.AUDIO_IDENTITY{text-align: right;}
.top_cont.AUDIO_IDENTITY h4{    margin-top: 100px;}
.packaging_main_frame .visual_all_frame{
margin-top: 0px;    
}
.packaging_main_frame .pack_middle_main_frame{margin-top:60px;}
.packaging_main_frame .sustain_right_img.right img{float: right;
width: 80%;
margin-top: 130px;}
.packaging_main_frame .sustain_right_img img{float: none;
width: 80%;
margin-top: -150px;}
.packaging_main_frame .sustain_left h3{font-family: Raleway-ExtraBoldr;      margin-bottom: 20px;
font-size: 26px;}
.packaging_main_frame .sustain_left ul li{   
margin-bottom: 10px;
list-style-type: square;  
font-size: 15px;
line-height: 25px;}
.packaging_main_frame .sustain_left ul li span{font-family: Raleway-ExtraBoldr;}

.packaging_main_frame .brand_gap_frame img {
box-shadow: 10px 10px 10px #cccccc52;
}









.brandfactory{margin-top: 4%;}
.slick-dots {
bottom: 0px !important;
z-index: 9999999999999999;
position: relative;
}
.brandfactory-middle .bordern{border-color:#133716 ;margin-bottom: 20px;}  
.ethcilmain_frame_middle{}
.ethcilmain_frame{margin-top: 4%;
text-align: center;
display: inline-block;
width: 100%; }
.ethcilmain_frame_middle h2{   
font-size: 40px;
font-family: Raleway-ExtraBoldr;
text-transform: uppercase;
color: #5a113b;}
.ethcilmain_frame_middle .bordern{
border-color:#5a113b;
margin-bottom: 15px;
}
.ethi-left_frame img{width: 60%;
margin-left: 10%;}
.ethi-left_frame ul {padding:0px;}
.ethi-left_frame ul li{font-family: 'Raleway', sans-serif;
font-size: 16px;
text-align: left;
line-height: 27px;
margin-bottom: 10px;}
.ethcilmain_frame_middle_cont{margin-top: 40px;}
.ethcilmain_frame_middle p{font-family: 'Raleway', sans-serif;
font-size: 16px;}
.making_frame{    padding-bottom: 25px;
background: #d7dfe7;
text-align: center;
padding-top: 25px;}
.making_frame p{    font-family: 'Raleway', sans-serif;
font-size: 16px;}
.making_frame p span{
font-family: Raleway-ExtraBoldr;
}
.since_frame ul li:hover .pop-hover{display: inherit;}
.pop-hover{text-transform: uppercase;
position: absolute;
top: 0px;
background: #00000094;
width: 100%;
height: 100%;
color: #fff;
display: none;}
.pop-hover h3{     height: 100%;
width: 100%;}
.makingimg_frame p{
font-family: 'Raleway', sans-serif;
font-size: 16px;
text-align: center;
margin-top: 30px;
}
.makingimg_frame img{height:100%;}
.pop-hover h3 a{    height: 100%;
width: 100%;
color: #fff;
text-decoration: none !important;
display: inline-block;
padding-top: 40px;}
.canhelp_frame_all{    top: 0px;
position: absolute;
width: 100%;}
.canhelp_frame{    text-align: center;
background: #f6f6f6;position: relative;}
.canhelp_frame p{font-family: 'Raleway', sans-serif;    font-size: 16px;}
.canhelp_frame p span{    font-family: Raleway-ExtraBoldr;}
.top-cont_frame{text-align: center;
font-size: 17px;}
.since_frame p{font-family: 'Raleway', sans-serif;font-size: 17px;}
.since_frame ul{    padding: 0px;
list-style-type: none;
padding-top: 30px;
}
.since_frame ul li{    width: 31%;
float: left;
position: relative;
margin: 1%;}
.since_frame{width: 100%;
display: inline-block;
margin-bottom: 40px;    padding-top: 40px;}
.left_logo_frame img{    width: 140px;    margin-top: 6px;} 
.lunch-now-frame .top_lunch_now h4{
font-weight: bold;
font-size: 36px;
font-family: Raleway-ExtraBoldr;
}
.lunch-now-frame .top_lunch_now h4 a{
color: #fff;
background: #414141;
text-decoration: none !important;
font-size: 26px;
padding: 10px 40px;
display: inline-block;
margin-bottom: 30px;
}
.video-container_home_paage{position: relative;}
.iamg_frame_all{
height: 140px;
}
.reach-frame_frame .container{max-width: 980px;}
.throuth_frame{}
.throuth_frame p{
font-family: 'Raleway', sans-serif;
font-size: 16px;
margin-top: 50px;
line-height: 30px;    
}
.throuth_frame .col-md-7.new{margin-top: 25px;}
.throuth_frame .col-md-7.new p{text-align: right;
margin: 20px 0px;}
.throuth_frame .col-md-7.new h3{font-family: trendsetter-Regular;
font-size: 45px;
font-weight: 500;
color: #133716;
text-align: center;
margin-top: 30px;}
.throuth_frame img{}
.throuth_frame .col-md-4.col-n img{    width: 100%;}
.reach_top_cont h2{
font-family: Raleway-ExtraBoldr;
font-size: 40px;
color: #133716;
}

footer h4{   
font-family: Raleway-ExtraBoldr;
color:#4d4e51 ;
}
.border_footer{width: 60px;
border: 1px solid #4d4e51;}
.reach_top_cont p{
margin-bottom: 30px;
font-family: 'Raleway', sans-serif;
font-size: 18px;
margin-top: 20px;
line-height: 42px;
}
.reach-frame_frame{background: #ededed;
padding-top: 4%;
padding-bottom: 4%;}
.bordern{border: 1px solid;
width: 140px;
margin: 0 auto;}
.top_lunch_now{    text-align: center;
margin-top: 30px;}
.speed_frame h5{font-family: Raleway-ExtraBoldr;
margin-top: 10px;}
.speed_frame p{font-family: 'Raleway', sans-serif;    font-size: 17px;
margin-top: 20px;
}
.reach_top_cont{text-align: center;}
.lunch_now_buttomframe{}
.lunch_now_buttomframe .speed_frame{        padding-bottom: 30px;
text-align: center;
padding-top: 0px;}
.lunch_now_buttomframe .speed_frame:hover{background:#dbdbdb;box-shadow: 5px 5px 5px #cccccca3;}
.lunch_now_buttomframe .speed_frame img{    width: 70px;
margin: 0 auto;margin-bottom: 20px;}
.lunch-now-frame{
margin-bottom: 20px;
}
.lunch-now-frame .top_lunch_now p{
font-family: 'Raleway', sans-serif;
font-size: 17px;
line-height: 27px;
font-weight: bold;
}
.lunch_now_buttomframe .speed_frame .iamg_frame_all.new img{    padding-top: 50px;}


.react-tabs__tab{
font-size: 17px;
padding: 5px 30px;
}
.react-tabs{    border: 1px solid #cccccc70;}
.react-tabs__tab-list{border-bottom: 1px solid #cccccc70;}
.home-catelist{
margin-top: 1%;  
}
.brandfactory-middle{
text-align: center;  
}
.img-all-frame{}
.img-all-frame img{    width: 42%;
float: left;}
.img-all-frame img:nth-child(2){margin-left: 10%;
margin-top: 8%;}
.brandfactory-middle h2{   color: #133716;    font-size: 40px;
font-family: Raleway-ExtraBoldr; }    
.react-tabs__tab--selected {
background: #414141;
border-color: #414141;
color: #fff;
border-radius: 0px 0px 0 0;
}
.home-catelist ul{
padding: 0px;
list-style-type: none;
padding-left: 50px;
}
.home-catelist ul li{
margin: 15px 0px;
font-size: 15px;
list-style-type: initial;
color: #414141;
text-transform: capitalize;
}
.container.Cate{
margin-bottom: 4%;
}
.container.Cate h3{
font-family: Raleway-ExtraBoldr;
margin-bottom: 5%;
margin-bottom: 25px;
text-align: center;
font-size: 34px;
}
.contactus-page h1{          
text-align: center;
font-family:Elephant;
font-size: 45px;
margin-bottom: 3px;
margin-top: 0px;
letter-spacing: 2px;
color: #000;
}
.bodycare-industry-Globalization-bottom .bodycare-industry-Globalization-bottomleftcont{  padding: 50px 40px;  background: #90d1bb;}
.bodycareNewpage .bodycare-industry-Globalization .container{ max-width: 100%; width: 100%; }
.bodycareNewpage .bodycare-industry-Globalization .container .col-md-8{    padding: 0px;}
.bodycareNewpage .bodycare-industry-Globalization .container .col-md-8 p{line-height: 36px;}
.bodycareNewpage .bodycare-industry-Globalization .container .col-md-4{    padding: 0px;}
.bodycareNewpage .bodycare-industry-Globalization .container .col-md-4 img{width: 80%;margin-top: 110px;}
.Mobile_menu{display: none;}
.dropdown-content {
display: none;
position: absolute;
background-color: #fff;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
}
.dropdown-content a {
color: #262626;
padding: 7px 10px;
text-decoration: none;
display: block;
font-size: 16px;
font-weight: normal;
}
.dropdown-content a:hover{
color: #262626;
text-decoration: none;
background-color: #f5f5f5;
}
/*.navbar-nav2>li:hover .dropdown-content {display: block;}*/
.show{display: block;}
.header-top span a{font-family: arial;
color: #000 !important;
font-weight: bold;
font-size: 15px;
font-family: 'Raleway', sans-serif;
font-weight: 600;}
.header-top p span:nth-child(2){font-family: 'Raleway', sans-serif;
font-weight: 600;}
.dropdown-menu>li>a{padding: 9px 25px;}
.nav .dropdown-menu li:last-child a {
padding: 9px 25px;
}
.navbar-nav>li>.dropdown-menu{border:0px;}
#myBtn {
display: none;
position: fixed;
bottom: 20px;
left: 20px;
z-index: 99;
border: none;
outline: none;
background-color: #414141;
color: white;
cursor: pointer;
padding:2px 8px 5px 8px;
border-radius: 5px;
font-size:28px;
line-height: 0px;
}       
.contactus-page{    margin-bottom: 5%;
margin-top: 4%;}
.contactus-page .container-main{    margin: 0 auto;
display: table;
width: 100%;    margin-top: 4%;
}
.right-contact h4{    font-size: 28px;
    text-align: center;}
.slick-dots li button:before{    font-size: 12px;}
.slick-dots li{    margin: 0 2px;}
.contact-left img{margin: 0 auto;
width: 100%;
margin-top: 50px;
-ms-transform: rotate(-9deg); /* IE 9 */
transform: rotate(-9deg);}
.contactus-page p{text-align:center;font-family: Myriad Pro;    font-size: 16px;    letter-spacing: 1px;}
.header-top{    background:url(../src/img/FooterBg.jpg);
    background-position: 0% -30%;
text-align: right;    padding: 10px 0px;}
.header-top p{    margin: 0px;
color: #000;
font-size: 15px;
text-align: left;}
.header-top p.service-owner {     
    
}
.header-top p img{width: 22px;
position: relative;
top: -4px;}
.header-top .right_sco_icon p img{margin-right: 10px;}
.header-top .right_sco_icon p{text-align: right;}
.header-top p span:nth-child(1){    top: 3px;
margin-right: 5px;
position: relative;}
.header-top p span:nth-child(2){    margin-right: 7px;}
.header-top p span:nth-child(3){    top: 2px;
position: relative;}
.navbar-default {
background-color: #fff;
border-color: #fff;
min-height: initial;
margin-bottom: 0px;
}
.navbar-brand {
float: none;
height: auto;
padding: 0px;
font-size: 18px;
line-height: 0px;
}
.navbar-default .navbar-brand img{width:125px;}
.navbar-default .navbar-nav>li>a{line-height:65px;font-family: BRADHITC;
font-size: 24px;}
.navbar-default .navbar-nav>li>a.active:after {
content: '';
position: absolute;
bottom: 20px;
left: 42%;
margin: 0 auto;
width: 0;
height: 0;
border-top: 8px solid #070606;
border-left: 8px solid transparent;
border-right: 8px solid transparent;
} 
.indexErr {
color: #ff5722;
margin: 0px;
font-size: 11px;
}
.whatsapp_chatbot{    width: 141px; bottom:1px; right:30px; position: fixed; z-index: 99999999;}
.whatsapp_analytics{color: #fff;background-color:#414141;}
.react-multi-carousel-item a img{    width: 100%;
padding: 10px;}
.Instagram-bottom-section{    margin-top: 3%;
padding-bottom: 3%;}
.Instagram-bottom-section h4{     font-family: Raleway-ExtraBoldr; text-align:center; color: #000000; font-size: 26px; margin-bottom: 1%; font-weight: bold;}
.Instagram-bottom-section p{text-align: center;
margin-top: 1%;}
.Instagram-bottom-section p a{color: #414141;
text-decoration: none !important;
border: 1px solid #414141;
font-size: 17px;
font-weight: 600;
padding: 6px 20px;
display: inline-block;}  
.img-responsive{width: 100%;}
.Instagram-bottom-section p a:hover{background: #414141;color: #fff;}
.nav li:last-child a{padding: 10px 0px 10px 15px;}
.carousel-control.left{background-image: linear-gradient(to right,rgba(0, 0, 0, 0) 0,rgba(0, 0, 0, 0) 100%);}
.carousel-control.right{background-image: linear-gradient(to right,rgba(0, 0, 0, 0) 0,rgba(0, 0, 0, 0) 100%);}
.carousel-indicators .active{background-color: #050505; }
.carousel-indicators li{border:1px solid #000;}
#about-frame{background:url(https://ik.imagekit.io/deepak/back-middle_HAiP_3bwb.jpg);    background-size: cover;
background-repeat: no-repeat;         padding: 11% 0px 10% 0px;} 
.about-frame-main-middel{    width: 58%;
margin-left:40%;
margin-top:0%;}
.about-frame-main-middel a{    margin-top: 10px;
display: inline-block;
text-decoration: none !important;
background: #e7a331;
color: #fff;
padding: 9px 15px;}
.about-heading {position:relative;}
.about-heading h3{font-family: 'Pacifico', cursive;    margin-bottom: 20px;}
.about-heading img{    width: 28px;
position: absolute;
top: -14px;
right: 0px;
left: 77px;}
.headeingamd h3 {
font-family: 'Pacifico', cursive;
margin-bottom: 20px;
font-size: 24px;
}
.responsive{width: 100%;}
.hotel-amenities-top-middle{padding-bottom: 2%;
text-align: center;
background: #e6e6e6;
padding-top: 3%;}
.hotel-amenities-top-middle.new{

background: url(https://ik.imagekit.io/deepak/spa-back_gEOYBX-xl.jpg);
background-size: cover;
background-repeat: no-repeat;
}
.pa-tag-heading.na h5{   top: -10px;    position: relative;}
.pa-tag-heading.na p{   width:100%;}
#new{
background:url(https://ik.imagekit.io/deepak/about-us-background_pCenHl09cT.jpg);
background-size: cover;
}
#new p{width:100%;}
.hotel-amenities-top-middle.our-misson{
background: #fff;
}
.hotel-amenities-top-middle.our-misson .pa-tag-heading p{width:100%;}
.hotel-amenities-top-middle p{    margin: 0 auto;
width: 65%;
margin-top: 2%;
margin-bottom: 3%;
line-height: 26px;
font-family: Times New Roman;
font-size: 20px;}
.headeingamd-main{    text-align: center;}
.hotel-cate-normaol-main-left {}
.hotel-cate-normaol-main-left ul{padding:0px;}
.hotel-cate-normaol-main-left ul li{font-family: Times New Roman;
list-style-type: none;
font-size: 20px;}
.hotel-cate-normaol-main-left p{    font-family: Times New Roman;
font-size: 20px;
line-height: 25px;
margin-top: 25px;}
.hotel-cate-normaol-main-left ul li img{    margin-right: 4px;
top: -2px;
position: relative;
width: 14px;}
.hotel-cate-normaol-main-right {}
.hotel-cate-normaol-main-right ul{padding:0px;}
.hotel-cate-normaol-main-right ul li{font-family: Times New Roman;
list-style-type: none;
font-size: 20px;}
.hotel-cate-normaol-main-right p{    font-family: Times New Roman;
font-size: 20px;
line-height: 25px;
margin-top: 25px;}
.hotel-last-img{text-align:center;}
.hotel-cate-normaol-main{margin: 0 auto;
display: table;
width:65%;    margin-top: 4%;}
.hotel-last-img img{width: 330px;
margin-top: -40px;} 
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{background:#fff;}
.sect-id-naormal{margin-top: 2%;
margin-bottom: 0%;}
.hotel-cate-normaol-main-right ul li img{    margin-right: 4px;
top: -2px;
position: relative;
width: 14px;}
.headeingamd{    display: inline-block;
position: relative;}
.headeingamd img {
position: absolute;
top:-10px;
right: -10px;
width: 25px;
}
.about-frame-main-middel p{    font-family: "Times New Roman", Times, serif;}
.our-client-page ul{}
.our-client-page ul li{    width: 20%;
display: inline-block;
float: left;}
.our-client-page ul li img{    width:80%;
margin: 0 auto;}
.client-heading {    margin: 0 auto;
position: relative;
text-align: center;
width: 160px;
margin-bottom:2%;
}
.client-heading  h3{font-family: 'Pacifico', cursive;
margin-top: 40px;}
.client-heading img{    position: absolute;
top: -10px;
right: 0px;
width: 25px;}
.our-client-page {margin-bottom:3%;}

footer{background:url(../src/img/FooterBg.jpg);
    background-position: 0% 30%;
    padding-top:10px; 
       padding-bottom: 50px;      background-size: cover;  border-top: 0px solid #7c5151ab;
padding-top: 30px;}   
.footer-left-frame img{
margin-top: 40px;
width: 160px;
display: block;
max-width: 100%;
height: auto;
}   
.footer-left p{    margin-left:0px;
margin-top: 10px;

font-size: 15px;
line-height:26px;
} 
footer .footer-left2 ul .cont-footer a{background: #fff;padding:10px 40px;}
footer .footer-left2 ul .cont-footer a:hover{background: #414141; color: #fff;}
.footer-left p span{display: block;
margin-bottom: 10px;}
.footer-left p span a{color: #000;}
.footer-right-list{margin-top: 0px!important;}
.footer-left p  span:last-child{    text-transform: lowercase;}
.footer-left {float:left;}
.footer-left2{float:left;}
.footer-left2 ul{list-style-type:none;margin-top:10px;     margin-left: 0px;padding: 0px;}
.footer-left2 ul li a{    color: #333;
text-transform: uppercase;

margin-bottom:10px;    display: inline-block;
font-size: 14px;
line-height: 18px;}
.footer-left2 ul.footer-right-list ~ a img{width:60%;}
.footer-top-heading {}
.clear{clear:both;}
.navbar-nav2 {
margin: 0px;
display: -webkit-flexbox;
display: -ms-flexbox;
display: -webkit-flex;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-flex-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-flex-line-pack: center;
-ms-flex-line-pack: center;
-webkit-align-content: center;
align-content: center;
-webkit-flex-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
float: right;
padding: 0px;
}
.logo img {
width: 170px;
height: auto;
margin-top: 13px;
}
.logo { 
text-align: center;
margin-top: 15px;
}
.col-md-12.mobile{
display: none;
}
.navbar-nav2>li {
float: left;
list-style-type: none;
font-weight: 600;
font-size: 18px;
}
.navbar-nav2>li>a:hover{
text-decoration: none;
background: #DCDCDC;


}
.navbar-nav2>li>a img{
width:170px;
}
.navbar-nav2>li>a {
line-height: 45px;
font-family: BRADHITC;
font-size: 18px;
color: #000;
padding-top: 15px;
padding-bottom: 15px;
position: relative;
padding: 10px 25px 10px 25px;
font-family: 'Raleway', sans-serif;
font-weight: normal;
}
footer form{    margin-top: 35px;
width:100%;}
.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
display: block;
max-width: 100%;
height: auto;
}
.slick-slider{overflow: hidden;}
.slick-dots{bottom:25px;}
.video-frame iframe{width:100%; height: 770px;;}
.footer-top-heading h3{    font-weight: bold;
float: left;
text-transform: uppercase;
margin-left: 20px;
font-size: 24px;}
footer .form-control{   border-radius: 0px;
border: 0px solid #0000008c;
font-size: 16px;
background: #eceaef;}
footer .btn{
background: #6f7072;
border: 0px;
float: none;
font-size: 16px;
border-radius: 0px;
color: #fff !important;
width: 100%;
}
.right-contact .form-control{    border-radius: 0px;
border: 2px solid #0000008c;
font-size: 16px;}
.right-contact .btn{background: #606060 !important;
    color: #fff !important;
border: 0px;
float: right;
font-size: 16px;
border-radius: 5px;}
.footer-top-heading ul{        float: right;
margin-top: 15px;}
.footer-top-heading ul li:nth-child(1){    margin-right: 20px;}
.footer-top-heading ul li{    display: inline-block;
list-style-type: none;}
.footer-top-heading ul li a{    color: #000;
font-size: 18px;
padding-right: 3px;
padding-left: 3px;}
.not-all-product-all-bottom{
width: 70%;
margin: 0 auto;
margin-top: 6%;
margin-bottom: 5%;}	
.not-all-product-all{position:relative;    margin-top: 3%;}
.lable-footer-img{}
.left-img-po{    position: absolute;
bottom: 0px;
left: 0px;}
.left-img-po img{width:210px;}
.right-img-po{    position: absolute;
bottom: 0px;
right: 0px;}
.right-img-po img{width:210px;}
.not-all-product-all-left h3{font-family: Myriad Pro;   text-transform: uppercase;    color: #000;    font-weight: normal;}
.not-all-product-all-left p{font-family: Times New Roman;    font-size: 19px;    line-height: 24px;}
.not-all-product-all-right h3{font-family: Myriad Pro;   text-transform: uppercase;    color: #000;    font-weight: normal;}
.not-all-product-all-right p{font-family: Times New Roman;    font-size: 19px;    line-height: 24px;}
.not-all-product-all-top{text-align:center;} 
.not-all-product-all-top h3{font-family: Myriad Pro;   text-transform: uppercase;    color: #000;    font-weight: normal;    line-height: 30px;}
.not-all-product-all-top p{font-family: Times New Roman;    font-size: 20px;}
.all-things-green--you{text-align:center;position:relative;    margin-top: 2%;}
.all-things-green--you h3{font-family:Gotham-Bold;    text-transform: uppercase;    color: #000;}
.all-things-green--you p {font-family: Times New Roman;    font-size: 20px;    margin-top: 25px;}
.all-things-green--you-bottom{margin-top:60px;}
.vott-po{}
.all-things-green--you-top{    position: relative;}
.all-things-green--you-bottom{    position: relative;}
.all-things-green--you img{margin-top:-350px;}	
.packging-section-left img{width:100%;}
.packging-section-left-bo-img{margin-left: 90px;}
.packging-section-left-bo-img h3{font-family: Gotham-Bold;}
.packging-section-left-bo-img p{
font-family:Times New Roman;
font-size:20px;
line-height:25px;	
}
.packging-section-right-left{    width: 40%; float:left;}
.packging-section-right-right {    width: 60%; float:left;}
.packging-section-right-left h4{font-family: Gotham-Bold;}
.packging-section-right-left p{font-family:Times New Roman;
font-size:20px;
line-height:25px;	}
.packging-section-right-left img{    display: table;
width: 72%;
margin: 0 auto;}
.packging-section-right-right h4{font-family: Gotham-Bold; margin-top: 13%;}
.packging-section-right-right p{font-family:Times New Roman;
font-size:20px;
line-height:25px;}
.packging-section-right{margin-top:80px;}
.packging-section-right h2{    font-family: Myriad Pro;
font-size: 26px;
line-height: 27px;    margin-bottom: 6%;}
.packging-section-right-right p:nth-child(1){margin-top: 37px;}
.packging-section-right-right p:nth-child(4){    margin-top: 30px;}
.labot-section{}
.labot-section h2{font-family:Gotham-Bold;font-size: 45px;    line-height: 65px}
.labot-section h2 span{    font-size: 100px;
font-weight: bold;
display: block;    margin-bottom: 60px;}
.labot-left{    float: left;
width: 30%;
}
.labot-section .container{padding-left:119px;}
.labot-left img{    display: table;
width: 80%;
margin: 0 auto;} 
.labot-right img{width:80%;margin-top: -118px;}
.labot-middle{float:left;width:30%;    padding-left:10px;}
.labot-middle h3{font-family:Gotham-Bold;}
.labot-middle p{     font-family: Times New Roman;
font-size: 20px;
line-height: 25px;    margin-top: 25px;
}     
.labot-right{    float: left;
width: 40%;
}
.labotman-section-left{    width: 30%;
float: left;}
.labotman-section-left h3{font-family:Gotham-Bold;}
.labotman-section-left p{ font-family: Times New Roman;
font-size: 20px;
line-height: 25px;}
.labotman-section-right p{ font-family: Times New Roman;
font-size: 20px;
line-height: 25px;margin-top: 55px;}
.labotman-section-main{display:inline-block;width:100%;margin-top:40px;}
.labotman-section-middle{    width: 40%;
float: left;}
.labotman-section .container{padding-left: 119px;}
.labotman-section-middle img{width:100%;    position: relative;
top: -20px;}
.labotman-section-right{    width: 30%;
float: left;}

.labotman-section-main-bottom .container{}
.labotman-section-main-bottom  p{font-family: Times New Roman;
font-size: 20px;
line-height: 25px;
width: 85%;
margin: 0 auto;
text-align: center;
margin-bottom: 3%;}
.lable-bodyimg-frame img{top: 23%;
position: absolute;
left: 0px;
width: 130px;}
.main {position:relative;}

.privel-label-newpage .top-video_privel video{width: 100%;}
.bottom_heading_section{ margin-top: 2%;text-align: center;}
.bottom_heading_section h5{ font-weight: bold; color: #4a4a4a; font-size: 20px; line-height: 28px; margin-top: 40px;}
.bottom_heading_section h1{    font-family: broadwayregular;
font-size: 60px;
margin-top: 45px;
margin-bottom: 45px;} 
.bottom_image_effect p span{font-size: 21px;font-family: BRLNSR;}

.privel-label-newpage p{ line-height: 28px; font-size: 18px; margin: 0px;}  
.privel-label-newpage p span{    font-family: BRLNSR;
font-weight: bold;
font-size: 26px;}
.privel-label-newpage p span.First_let_n{font-family: 'Raleway-ExtraBoldr';
    font-size: 22px;}

.privel-label-newpage p span.First_let{  font-family: BRLNSR;  font-weight: bold; font-size: 26px; }
.privel-label-newpage p span.highlight{    font-weight: 500;
font-family: BRLNSR;
font-size: 22px;} 
.cont_frame_prilabel{margin-top: 60px;}   
.bottom_image_effect{
background-image: url("../src/lab/flo-back.jpg");
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
padding: 120px 0px;
margin-top: 30px;
}
.bottom_image_effect p{    text-transform: uppercase;background: #e8e4f2d6;  padding: 40px 50px;      text-align: center;  
font-weight: bold;
letter-spacing: 1px;}
.Top_last_cont_prilable{
margin-top: 60px;
margin-bottom: 60px;

}
.product_list_pri-lable ul{padding: 0px;}
.product_list_pri-lable ul li{
width: 19%;
list-style-type: none;
margin-left: 3%;
margin-right: 3%;
padding: 8px 0px;
float: left;
margin-bottom: 40px;
}
.product_list_pri-lable ul li h5{    
padding: 5px;
margin-top: 10px;
text-align: center;
font-size: 17px;
height: 55px;
font-weight: bold;
}
.product_list_pri-lable ul li:nth-child(1) h5{background: #f3837b;}
.product_list_pri-lable ul li:nth-child(2) h5{background: #b3b3db;}
.product_list_pri-lable ul li:nth-child(3) h5{background: #b3e3f3;}
.product_list_pri-lable ul li:nth-child(4) h5{background: #b3b3db;    line-height: 50px;}
.product_list_pri-lable ul li:nth-child(5) h5{background: #d3d3d3;height: 94px;    padding-top: 30px;}
.product_list_pri-lable ul li:nth-child(6) h5{background: #bfdb9b;height: 94px;padding-top: 20px;}
.product_list_pri-lable ul li:nth-child(7) h5{background: #67c6d9;height: 94px;padding-top: 28px;}
.product_list_pri-lable ul li:nth-child(8) h5{background: #fbcbc3;height: 94px;}
.product_list_pri-lable ul li img{border-radius: 100%;    width: 85%;
margin: 0 auto;}
.product_list_pri-lable h4{     
    color: #626463;
font-size: 24px;
margin-bottom: 50px;
text-align: center;
line-height: 34px;
letter-spacing: 1px;
font-weight: bold;  
}

.product_list_pri-lable h4.proudly_we{
font-size: 36px;
font-weight: bold;
text-shadow: 1px 1px 2px #000;  
}
.TheDeadliestIngredientSqualane.banish .Heading_tu h1{color: #133d0f;}
.TheDeadliestIngredientSqualane.banish .Heading_tu h4{color: #133d0f;}
.TheDeadliestIngredientSqualane.banish.uneven-skin-tone h1{color: #3c6f87;}
.TheDeadliestIngredientSqualane.banish.uneven-skin-tone .Heading_tu h4{color: #3c6f87;}
.TheDeadliestIngredientSqualane.banish.uneven-skin-tone .Heading_tu p span{color: #3c6f87;}
.TheDeadliestIngredientSqualane.banish.coconut-oil-benefits-for-hair h1{color: #643e29;}
.TheDeadliestIngredientSqualane.banish.coconut-oil-benefits-for-hair .Heading_tu h4{color: #643e29;}
.TheDeadliestIngredientSqualane.banish.skin-exfoliation .Heading_tu h1{color: #643e29;}
.TheDeadliestIngredientSqualane.banish.skin-exfoliation .Heading_tu h4{color: #643e29;}
.TheDeadliestIngredientSqualane.banish .Heading_tu p span{font-weight: bold;}
.TheDeadliestIngredientSqualane.banish.skin-exfoliation.SEBUM .Heading_tu h4{
    
text-align: left;
font-size: 18px;

}

.Marquee_main_image_frame {width:320px;margin-right: 10px;}
.Marquee_2 .marquee{animation-direction:reverse !important;}
.Marquee_2{ margin-top: 50px; margin-bottom: 60px; }
.Marquee_1 h3{    color: #604450;text-align: center;margin-bottom: 40px;    font-size: 42px;
font-family: Aller_Rg;}
.AccordionItem_main_section h4{    color: #626463;
font-size: 24px;
margin-bottom: 30px;
text-align: center;
line-height: 34px;
letter-spacing: 1px;  
font-weight: bold;
}


.AccordionItem_main_section form{
display: -webkit-flexbox;
display: -ms-flexbox;
display: -webkit-flex;
-webkit-flex-wrap: wrap;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-flex-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-flex-line-pack: center;
-ms-flex-line-pack: center;
-webkit-align-content: center;
align-content: center;
-webkit-flex-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
}


.AccordionItem_main_section input { display: none; }

.AccordionItem_main_section label { 
cursor: pointer; 
display: block; 
border: solid 0px transparent;
text-align: center; 
line-height: 40px; 
border-radius: 3px; 
float: left;
width: 110px;  
margin-bottom: 0px;     

}

.AccordionItem_main_section label:last-child { margin-right: 0; }
.AccordionItem_main_section label {     
text-transform: uppercase;
border-bottom: 5px solid #fbd7d7; 
position: relative;
display: block;
white-space: nowrap;

}
.AccordionItem_main_section label img{width: 100%;}
.AccordionItem_main_section .primary_main{ box-shadow: 5px 5px 5px #cccccc40;  margin-right: 5px; }   
.AccordionItem_main_section .primary_middle{ font-weight: bold;width: 100%;
}

.AccordionItem_main_section{margin-bottom: 60px;margin-top: 30px;}

.AccordionItem_main_section article { 

width: 0;
height: 100%;  
overflow: hidden; 
-webkit-transition: width .25s linear, opacity .2s linear; 
transition: width .25s linear, opacity .3s linear; 
position: relative; 
top: 0px;
margin-bottom: 0;
padding: 0;
margin-right: 0px;
opacity: 0;
float: left;

}
.AccordionItem_main_section div:last-child article { margin-right: 0; }
.AccordionItem_main_section article p  img{
width: 332.5px;
height: 496px;          
}

.AccordionItem_main_section div > input:checked  ~ article { 
width: 332px;
opacity: 1;              
padding-left: 0px;
margin: 0px;
padding: 0px;
top: 0px;
border-bottom: 5px solid #3f3a50;
position: relative;}

input:checked + label, label:hover { opacity: 1;     border-bottom: 5px solid #3f3a50;}
.AccordionItem_main_section div > input:checked .primary_main label{padding: 0px;}
.product_green_heading h5{     
background: #cfe1ed;
text-transform: uppercase;
display: inline-block;
text-align: center;
padding: 5px 30px;
font-weight: bold;
color: #505155;
line-height: 31px;
font-size: 20px;
letter-spacing: 1px;  
}
.product_green_heading h5 span{font-family: BRLNSR;
font-size: 30px;
} 
.product_green_heading {
text-align: center;
margin-bottom: 30px;
}
.AccordionItem_main_section_mobile .AccordionItem_main_section_mobile_middle{    margin-bottom: 0px;}  
.AccordionItem_main_section_mobile .AccordionItem_main_section_mobile_middle h5{
background: #d1eef6;
text-transform: uppercase;
text-align: center;
padding: 10px 10px;
font-weight: bold;
font-size: 15px;
line-height: 22px;
height: 60px;
line-height: 45px;
margin-bottom: 0px;
}
.AccordionItem_main_section_mobile .AccordionItem_main_section_mobile_middle img{width: 100%;}
.AccordionItem_main_section_mobile  .col-md-6{width: 50%;float: left;    padding: 5px;}

.AccordionItem_main_section_mobile .col-md-6:nth-child(2) .AccordionItem_main_section_mobile_middle h5{
line-height: 23px;
background: #f0d3e5;
}
.AccordionItem_main_section_mobile .col-md-6:nth-child(3) .AccordionItem_main_section_mobile_middle h5{
background: #d6e9d3;
}
.AccordionItem_main_section_mobile .col-md-6:nth-child(4) .AccordionItem_main_section_mobile_middle h5{
background: #d5ddf0;
}
.AccordionItem_main_section_mobile .col-md-6:nth-child(5) .AccordionItem_main_section_mobile_middle h5{
background: #fad8d7;
}
.AccordionItem_main_section_mobile .col-md-6:nth-child(6) .AccordionItem_main_section_mobile_middle h5{
background: #f0f3d4;
}
.AccordionItem_main_section_mobile{display: none;}
.product_green_heading span.plus{
position: relative;
font-size: 50px;
top: 5px;
}

.Start_now{text-align: center;}
.Start_now a{ 
background: #414141;
color: #fff;
text-decoration: none !important;
padding: 15px 20px;
display: inline-block;
margin-bottom: 50px;
font-weight: 500; 
}
.Start_now a:hover{background:#626463; color: #fff;}
.manufacturing_main_page .top-video_privel video{width:100%;}
.manufacturing_main_page .bottom-video_privel video{width:100%;margin-bottom: 40px;}
.manufacturing_main_page .bottom-video_privel img{width:100%;margin-bottom: 40px;}
.manufacturing_main_page .our-work-over-now{margin-top: 50px; margin-bottom: 50px;}
.our_range_section .marquee div{width: 600px;}
.our_range_section{ margin-top: 50px; margin-bottom:50px;}
.our_range_section .marquee div h3{font-weight: bold;}
.TheDeadliestIngredientSqualane  .Heading_tu h1{    
color: #0a508c;
font-weight: normal;
text-align: center;
font-size: 26px;
margin-bottom: 30px;
font-weight: 500;
font-weight: bold;
}
.TheDeadliestIngredientSqualane.banish.skin-exfoliation.SEBUM.Lanolin-Truth .Heading_tu h1{
color: #82674a;
}
.TheDeadliestIngredientSqualane.banish.skin-exfoliation.SEBUM.Lanolin-Truth .Heading_tu h4{
color: #82674a;
}
.TheDeadliestIngredientSqualane  .Heading_tu h4{ color: #0a508c;
font-weight: 500;
text-align: center;
font-size: 22px;
margin-top: 30px;
margin-bottom: 30px;
font-weight: bold;
}
.TheDeadliestIngredientSqualane  .Heading_tu p{font-size: 17px;
line-height: 28px;}
.TheDeadliestIngredientSqualane .Image_frame{margin-top: 20px;
margin-bottom: 30px;}
.TheDeadliestIngredientSqualane{    margin-bottom: 5%;}
.TheDeadliestIngredientSqualane .Image_frame-middle{width: 85%;  
margin: 0 auto;}  
.TheDeadliestIngredientSqualane.Tweaks .Heading_tu h1{color: #5b939a;    margin-top: 30px;}
.TheDeadliestIngredientSqualane.Tweaks .Heading_tu h1 span{font-size: 36px;
font-weight: 500;}
.TheDeadliestIngredientSqualane.Tweaks ul{
padding: 0px;
text-transform: uppercase;  
font-size: 17px;
font-weight: bold;
margin-top: 30px;
}  
.manufacturing_main_page .our_range_sectio_img_frame{
margin-top: 30px;
}  
.our_range_sectio_img_frame-top{}
.our_range_sectio_img_frame-top a:nth-child(1) img{ 
top: 1px;
width: 470px;
position: relative;   
left: 0px;
}
.our_range_sectio_img_frame-top a:nth-child(2) img{ 
margin-top: 3px;
width: 294px;    
margin-left: -2px;
}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-left{
float: left;
width: 323px;
}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-middle{
float: left;
width: 330px;  
}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-right{
float: left;
width: 317px;
}
.our_range_sectio_img_frame-bottom-left img{width:100%;}
.our_range_sectio_img_frame-bottom-middle img{width:100%;}
.our_range_sectio_img_frame-bottom-right img{width:100%;}
.our_range_sectio_img_frame-bottom-right img:nth-child(2){position: relative;
right: 10px;
width: 103%;}
.privel-label-newpage.manufacturing_main_page .our_range_sectio_img_frame-bottom-left img:nth-child(2){  margin-left: 0%;
width: 100%;}
.privel-label-newpage.manufacturing_main_page .tube_light_middle_section h1{margin-top: 4%;text-align: center;
margin-bottom: 5%;font-weight: bold;}    
.privel-label-newpage.manufacturing_main_page .tube_light_middle_section span{ font-size: 50px;}
.privel-label-newpage.manufacturing_main_page .tube_light_middle_section{position: relative;}
.privel-label-newpage.manufacturing_main_page .tube_light_middle_section .img-responsiveright-tue{    position: absolute;
right: 0px;
width: 50%;
z-index: -99;
}
.bottom-video_privel.Start_now-{}
.bottom-video_privel.Start_now- video{width: 100%;margin-bottom: 5%;}

.privel-label-newpage.manufacturing_main_page .tube_light_middle_section_top{  
float: right;
background: #fff;
width: 80%;
margin-top: 125px;   
padding: 25px 20px 12px 20px;
}
.privel-label-newpage.manufacturing_main_page .tube_light_middle_section_bottom{      margin: 0 auto;  margin-top: 12%;      margin-bottom: 5%;  float: right; width: 80%;}
.privel-label-newpage.manufacturing_main_page p{    font-size: 16px;     margin-bottom: 20px;}  
.privel-label-newpage.manufacturing_main_page p span.highlight{    font-size: 19px;  }
.privel-label-newpage.manufacturing_main_page-middle{background-image: url(../src/lab/Bottle.png);
background-repeat: no-repeat;
background-attachment: fixed;
background-size: 11%;
background-position: 4% -15%;}

.privel-label-newpage.manufacturing_main_page  .bottom-video_privel{    background: #fff;}
.privel-label-newpage.manufacturing_main_page .our_range_sectio_img_frame .container{padding-left: 10%;}
.TheDeadliestIngredientSqualane.Tweaks.skin .Heading_tu h1{color: #763f2a;}
.TheDeadliestIngredientSqualane.Tweaks.skin  ul{color: #763f2a;}  
.our_range_section{ width: 90%;
margin-bottom: 5%;
float:right;}
.TheDeadliestIngredientSqualane.Tweaks.skin .Heading_tu p span{    font-weight: 500;
font-size: 30px;}
.tube_light_middle_section_bottom img{width: 100%;}
.testing_main_frame .top-video_privel video{width:100%;margin-top: 20px;}
.testing_main_frame .product_list_pri-lable h4 span{font-size: 32px; color: #4c4c4c;}
.testing_main_frame .product_list_pri-lable.new{
margin-top: 50px;
display: inline-block;
width: 100%;
margin-bottom: 50px;

}
.testing_main_frame .product_list_pri-lable.new h4{margin-bottom: 25px;}
.testing_main_frame .testing_top_head{text-align: center;}
.testing_main_frame .testing_top_head img{
width: 320px;
margin: 0 auto;
margin-top: 30px;
margin-bottom: 30px;
}
.testing_main_frame .testing_top_head p{}
.testing_main_frame .testing_top_head span.first-spa{ 
font-size: 30px;
}
.testing_main_frame .testing_top_head span.zerocal{
font-weight: bold;

}
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(1){background: #d6edc3;}
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(3){background: #f6c6c6;}
.testing_main_frame .row.raw_metel_testing.botto .col-md-4:nth-child(2){background: #cff1fa;}
.testing_main_frame .row.raw_metel_testing{padding: 0px 15px;    margin: 0 auto;    width: 93%; }
.testing_main_frame .row.raw_metel_testing .col-md-4{padding: 0px;}
.testing_main_frame .raw_metel{    padding: 20px;}
.testing_main_frame .raw_metel p{    font-size: 17px;
font-weight: bold;
margin-top: 25px;
}
.testing_main_frame .raw_metel h4{
text-align: center;
text-transform: uppercase;
text-decoration: underline;
padding-top: 20px;
font-size: 24px;
font-weight: bold;
}  
.new_home_page p span{    font-weight: 500;
font-family: BRLNSR;
font-size: 18px;} 


.video-container_home_paage video{width:100%;margin-top: 0%; } 
.new_home_page_first_section .col-md-6{padding: 0px; }
.new_home_page_first_section .row:nth-child(2) .col-md-6{padding: 0px;}
.new_home_page_first_section{margin-top: 40px;margin-bottom: 40px;}
.bottom_con_all_homepagenew{ margin-bottom: 50px; }
.bottom_con_all_homepagenew p{    text-align: center;  font-size: 18px;}
.bottom_con_all_homepagenew p span{    display: block; }
.new_home_page_first_section .row:nth-child(1) .left_home_page_new_first_cont h3{ margin-top: 50px; margin-bottom: 70px;}
.left_home_page_new_first_cont h3{text-align: center;    color: #4e554d; font-weight: bold;    font-size: 22px;}
.left_home_page_new_first_cont h1{text-align: center;    color: #4e554d; font-weight: bold;    font-size: 22px;    margin-bottom: 70px;
    margin-top: 50px;}
.bottom_con_all_homepagenewnew span{
    display: inline !important;
}
.left_home_page_new_first_cont p{    font-size: 17px;padding: 0px 15px;line-height: 30px;}
.left_home_page_new_first_cont p.INNOVATING{
font-weight: bold;
font-size: 15px;
}
.complete_ready_sell h4{    font-weight: bold;
font-size: 28px;
margin-bottom: 40px;
text-align: center;
margin-top: 10px;
color: #000;}
.new_home_page_first_section .row:nth-child(2) .left_home_page_new_first_cont h3{ margin-top: 20px; margin-bottom: 30px;}
.complete_ready_sell{   background: #e4c3fc;
padding: 25px 0px 0px 0px;    z-index: 999;  
position: absolute;
width: 100%;
margin-top: 1480px;
position: relative;
width: 100%;
margin-top: 0px;}
.Address-main-dd b{ 
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}
.complete_ready_sell p:last-child{
margin: 0px;
padding-bottom: 60px;
}
.complete_ready_sell p{
text-align: center;
font-size: 17px;
color: #212529;
}
.Scrince_come_here_frame p:nth-child(3){
margin-bottom: 50px;
}
.Scrince_come_here_frame video:nth-child(1){margin-bottom: 40px;}

.complete_ready_sell p span{}
.Scrince_come_here_frame video{width: 100%;display: block;}
.Scrince_come_here_frame {    text-align: center;
padding-top: 50px;
padding-bottom: 40px;}
.Scrince_come_here_frame h4{    font-weight: bold;
font-size: 26px;
margin-bottom: 20px;}
.Scrince_come_here_frame p{    font-size: 17px;}
.Scrince_come_here_frame h3{
color: #285721;
font-size: 24px;
font-weight: bold;
margin-bottom: 40px;
font-family: BRLNSR;
}
.new_home_page .reachand_developent_newhomepage video{width:100%;}
.new_home_page .iamg_frame_all {
height: auto;
}
.new_home_page .lunch_now_buttomframe .speed_frame .iamg_frame_all.new img {
padding-top: 0px;
margin-bottom: 0px;
width: 70%;
}  
.new_home_page .lunch_now_buttomframe .speed_frame img{ padding-top: 0px;
margin-bottom: 0px;
width: 70%;}
.new_home_page .lunch_now_buttomframe .speed_frame:hover {
background: transparent;
box-shadow: 0px 0px 0px #fff;
}
.new_home_page .speed_frame h5{margin-top: 0px;}
.reachand_developent_newhomepage .reachand_developent_right{
    background: transparent;
    padding: 0px 0px;
    margin-left: 0px; 
}
.new_home_page video{width: 100%;}
.new_home_page .live_animalvideo video{ border: 0px solid #b4958c;     display: block;box-shadow: 5px 5px 5px #cccccc61; }
.reachand_developent_newhomepage h4{
    font-size: 60px;
    color: #6c7174;    
    font-family: ChaparralProRegular;
}
.reachand_developent_newhomepage h4 span{
    font-size: 64px;
}
.reachand_developent_newhomepage h5{    font-family: trendsetter-Regular;
    text-align: center;
    font-size: 45px;}
    .green_side_bottom h5{   
        font-family: trendsetter-Regular;
        text-align: center;
        font-size: 60px;
        color: #53714f; 
        margin-top: 30px;
        display: inline-block;
        width: 100%;
    }
    .join_side_new_home_page-left{
        margin-top: 20px;
    }
    .left_home_page_new_first_cont.lift{    width: 80%;
        margin: 0 auto;}
        .left_home_page_new_first_cont.lift p{margin-bottom: 30px;}
.reachand_developent_newhomepage p{
    margin-top: 27px;  
    font-size: 17px;  
    line-height: 29px;  
    text-align: center;  
    font-weight: bold;  
}
.Main_banner_hb{    margin-top: 1775px;     margin-top: 0px;}
.join_side_new_home_page p{
    font-size: 17px;
}
.left_home_page_new_first_cont.NW p span:nth-child(1){    font-size: 40px;}
.join_side_new_home_page{
margin-bottom: 50px;
background: #c5e1ef;
padding: 5px 0px;
} 
.green_side_bottom{width: 100%;}   
.join_side_new_home_page-middle{margin-top: 0px;} 
.join_side_new_home_page .container{ max-width: 100%; width: 97%;} 
.join_side_new_home_page .container .col-md-8{flex: initial;
max-width: 71.333333%; padding-top: 2%;}
.join_side_new_home_page .container .col-md-4{flex: initial;
max-width: 28.333333%;}
.join_side_new_home_page-middle img{
margin-left: 40px;
margin-top: 10px;
margin: 0 auto;
width: 130px;
margin-bottom: 0px;
margin-left: 25px;
}  
.join_side_new_home_page-right{margin-top: 0px;}
.join_side_new_home_page .col-md-2{padding: 0px;}
.GREEN-BRAND-FACTORY{   position: absolute;width: 100%;top: 730px;z-index: 99999; position: relative;
    width: 100%;
    top: 0px;}
.right_img_home_pagenew img{  visibility: hidden;  }
.right_img_home_pagenew.fi{ background-image: url(../src/img/BRAND.jpg);   background-attachment: fixed;background-repeat: no-repeat; background-position: 85%; }    
.right_img_home_pagenew.nd{ background-image: url(../src/img/new.png);   background-attachment: fixed;background-repeat: no-repeat; background-position: 13%; }    
.GREEN-BRAND-FACTORY{ z-index:9999; }
.Scrince_come_here_frame h4 span{  }   
.reachand_developent_newhomepage{background-image: url(../src/img/Graph.png);    margin-bottom: 40px;
padding: 80px 0px;    background-size: cover;}
.complete_ready_sell h4 span{    font-size: 45px;     position: relative;
top: 3px;}        
.Main_banner_hb_mid{width:100%;    display: flex;    height: 540px;
overflow: hidden;}
.Main_banner_hb_mid_left{width:40%; float: left;overflow: hidden;}
.Main_banner_hb_mid_left video {
width: 100.3%;
}
.Main_banner_hb_mid_right{width:60%; float: left;}
.Main_banner_hb_mid_right img{width:100%;}   
.join_side_new_mao{
display: flex;
width: 100%;
}
.Packaging_main_page video{width: 100%;}  
.Packaging_main_page .simply_text_add{margin-top: 70px;
margin-bottom: 70px;}  
.Packaging_main_page .simply_text_add p{font-size: 17px; text-align: center; line-height: 30px; }  
.Packaging_main_page p span.First{ font-size: 22px; font-weight: bold; }    
.Packaging_main_page p span.sect{ font-family: BRLNSR; font-weight: 500; font-size: 18px;} 
.Packaging-top-banner_video_borrom{margin-bottom: 40px;}  
.Packaging-top-banner_video{margin-bottom: 50px;margin-top: 20px;}
.packing_heading_frame-left{ text-align: center;}
.packing_heading_frame-left h1{
color: #898989;
text-shadow: 1px 0px 1px #000;
font-size: 55px;
}
.packing_heading_frame-left h4{    
color: #936a54;
font-weight: bold;
font-size: 21px;
margin-top: 15px;
line-height: 30px;
}
.packing_heading_frame-right{
background: #e3cbef;
padding: 40px 20px 30px 20px;  
}
.packing_heading_frame-right p{    
font-size: 17px;
}     
.packing_heading_frame-center{
background: #f2e6e6;
padding: 30px 20px 20px 20px;
margin-bottom: 50px;
margin-top: 50px;
margin-left: 10%;
}
.packing_heading_frame-center p{font-size: 17px;}
.middle_main_pack_pic_frame{position: relative;}
.middle_main_pack_pic_frame_back{
margin-bottom: 0px;
margin-top: 0px; 
}
.middle_main_pack_pic_frame_back img{ visibility: hidden;}
.middle_main_pack_pic_frame{  
background: url(../src/img/Packbr.png);
background-repeat: no-repeat;  
background-position: center;
background-attachment: fixed;
background-size: 41%;
height: 900px;
}    
.packaging_new_all_main{       
position: absolute;
width: 100%;
top: 100px;   
}
.packaging_new_all_clickone.one_1 .packaging_new_all_clickone_u{

} 
.packaging_new_all_clickone.one_2 .packaging_new_all_clickone_u{

} 
.packaging_new_all_clickone.one_3 .packaging_new_all_clickone_u{

} 
.packaging_new_all_clickone.one_4 .packaging_new_all_clickone_u{

} 
.packaging_new_all_clickone.one_5 .packaging_new_all_clickone_u{

} 
.packaging_new_all_clickone.one_6 .packaging_new_all_clickone_u{

}   
.packaging_new_all_clickone .packaging_new_all_clickone_u{
width: 340px;
display: inline-block;
padding: 15px 20px 1px 20px;
margin-bottom: 10px;

}
.packaging_new_all_clickone p{
font-weight: bold;
font-size: 17px;
text-align: center;
margin-bottom: 10px;  
}
.packaging_new_all_clickone p span{
font-weight: bold;
font-size: 38px;
line-height: 0px;
position: relative;
top: 4px;
}

.packaging_new_all_clickone.one_1{
position: relative;
left: 50px;
top: -40px; 
display: flex;
}
.packaging_new_all_clickone.one_1 .Plus_imh_all_packing{
width: 100px;
left: -21px;
position: relative;
top: 10px;
}
.packaging_new_all_clickone.one_2 .Plus_imh_all_packing{

width: 65px;
left: -12px;
position: relative;
top: 10px;
}
.packaging_new_all_clickone.one_3 .Plus_imh_all_packing{
width: 75px;
left: -5px;
position: relative;
top: 20px;

}
.packaging_new_all_clickone.one_4 .Plus_imh_all_packing{
width: 75px;
left: 15px;
position: relative;
top: 20px

}
.packaging_new_all_clickone.one_5 .Plus_imh_all_packing{
width: 65px;
left: 15px;
position: relative;
top: 8px;

}
.packaging_new_all_clickone.one_6 .Plus_imh_all_packing{
width: 100px;
left: 17px;
position: relative;
top: 5px;

}
.packaging_new_all_clickone.one_2{
position: relative;
left: 60px;
top: 50px;    
display: flex;
}
.packaging_new_all_clickone.one_3{
position: relative;
left: 20px;
top: 150px;
display: flex;
}
.packaging_new_all_clickone.one_4{
    position: relative;
    right: -80px;
    top: 0px;
    
    display: flex;
    }

    .packaging_new_all_clickone.one_5{
        display: flex;
        position: relative;
        right: -30px;
        top: 100px;
        
        }

.packaging_new_all_clickone.one_6{
position: relative;
right: -50px;
top: 200px;

display: flex;
}
.all_clickone_u_midum{width:auto;
font-weight: normal !important;}

.testing_main_frame_middle_main{

padding-bottom: 30px;

z-index: 99999999;
background: #fff;
}

.raw_metel_testing.first_main{
position: relative;

}

.testing_main_frame .row.raw_metel_testing.botto .col-md-4:nth-child(2){    z-index: 9999999999;
display: block;}   
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(3){    z-index: 9999999999;
    display: block;}  
    .testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(1){    z-index: 9999999999;
        display: block;}  

    .testing_main_frame .product_list_pri-lable.new{background:;}  
    .testing_main_frame .row.raw_metel_testing .raw_metel_img img{
        
    }  
    

    .Main_banner_hb_mid.Mob{display: none;}
    .Scrince_come_here_frame img{display: none;}
    .video-container_home_paage .home-page_tom_mobile{display: none;}
    .live_animalvideo .container--- .img-responsive{display: none;}  
    .right_img_home_pagenewMob{display: none;}  
    .left_home_page_new_first_cont.NW.Mob{display: none;} 
    .join_side_new_mao.mobile{display: none;} 
    .join_side_new_home_page-right.mob{display: none;}
    .make-top-banner video{width:100%;margin-top: 20px;}
    .custom-1mainpage.new .make-top-banner video{margin-top: 0px;}
    .make-top_pagecont{margin-top: 30px;margin-bottom: 50px;}
    .make-top_pagecont h1{ text-align: center;}
    .makeupnewpage .make-top_pagecont h1{ font-family:Mishella; font-size: 100px; line-height: 70px;}
    .right-imagemencarenew img{visibility:hidden;}
    .right-imagemencarenew {
        background: url(../src/lab/mencare4.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: 85%;
    }

   .mencarebottom-fixedsection{
    background: url(../src/lab/mencare6.jpg);
    background-attachment: fixed;
    background-size: 40%;
    background-repeat: no-repeat;
}
   .mencarebottom-fixedsection img{visibility: hidden;}
    .makeup-new-pageAd p{font-size: 17px;
    line-height: 32px;}
    .makeup-new-pageAd p span.Fid{font-size: 25px;
    font-weight: bold;}
    .makeup-new-pageAd p span.Fid2{
    font-weight: bold;
}
.row_frem_makenew2gif .col-md-2 .row_frem_makenew2gifim{width: 100%;
    }
.row_frem_makenew2gif .col-md-2{ }
.row.row_frem_makenew2gif {    margin: 0 auto;width: 90%;     margin-top: 20px;
margin-bottom: 20px;}
.make_new_imgf{background-image: url(../src/img/MakeuoV6.jpg);    background-attachment: fixed;background-repeat: no-repeat;    background-size: cover;
    background-position: center;    padding: 70px 0px 80px 0px;    margin-top: 50px; margin-bottom: 50px;}
.make_new_imgf .make_new_imgf_inner{    width: 95%;
    background: #ffffffd1;
    padding: 25px 20px 15px 20px;
    margin: 0 auto;}
    .row_frem_makenew2 p{    text-align: center;}
    .row_frem_makenewBo{ margin-bottom: 20px;}
    .Marquee_Makeup-page .Marquee_main_image_framemake{    width: 250px;}
    .Marquee_Makeup-page{margin-bottom: 50px;}
.packaging_new_all_clickone.one_1 .packaging_new_all_clickone_u p{    text-align: right;}  
.packaging_new_all_clickone.one_2 .packaging_new_all_clickone_u p{    text-align: right;}  
.packaging_new_all_clickone.one_3 .packaging_new_all_clickone_u p{    text-align: right;} 
.packaging_new_all_clickone.one_4 .packaging_new_all_clickone_u p{    text-align: left;}  
.packaging_new_all_clickone.one_5 .packaging_new_all_clickone_u p{    text-align: left;}  
.packaging_new_all_clickone.one_6 .packaging_new_all_clickone_u p{    text-align: left;}  
.Packaging-top-banner_video_middle .video_Heading h3{
font-family: KitaharaScript;
font-weight: bold;
font-size: 50px;text-align: center;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(1) .video_Heading h3{
    margin-left: -100px;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(2) .video_Heading h3{
    margin-left: 50px;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(3) .video_Heading h3{
    margin-left: 135px;   
}
.Marquee_Makeup-page .overlay{background: #000 !important;}
.Packaging-top-banner_video_middle{    padding-top: 40px;}
.middle_main_pack_pic_frame_main{background: #64cdc9; overflow: hidden;}
.top-video_privel.ma_veo_mob{display: none;}
.bottom-video_privel.mobile_manu_bot{display: none;}
.top-video_privel.testing_mobile{display: none;}
.top-video_privel.testing_mobile{display: none;}
.bottom-video_privel.Start_now-.pvt_lbl_mobile{display: none;}
.top-video_privel.Mobile{display: none;}
.video-container_home_paage .home-page_tom_Mobile{display: none;}
.Packaging-top-banner_video.Mobile{display: none;}
.Packaging-top-banner_video_borrom.mobile{display: none;}
.container.raw_metel_testing.first_main.mobile{display: none;}
.facecare_main_page{
    background: #000;
    padding: 30px 0px;
    margin-top: 25px;
}
.facecare_topsection{ display: flex;} 
.facecare_topsectionL.left{width: 27%;margin-top: 15px;} 
.facecare_topsectionL.left img{    opacity: 0.6;    }
.facecare_topsectionL_right1{height: 316px; overflow: hidden;position: relative;
    left: 100px;}
.facecare_topsectionL_right1 img{transition: transform .5s ease;}
.facecare_topsectionL_right1 img:hover{transform: scale(1.5);}
.facecare_topsectionL.mi{width: 24%;  margin-top: 250px;    margin-left: 2%;}  
.facecare_topsectionL.mi img{        
    opacity: 0.6;
    width: 100%;
    margin: 0 auto;
    
}
.facecare_topsectionL_mi{width: 95%; }

.facecare_topsectionL.right{width: 36%;} 
.facecare_topsectionL_right2{
    width: 85%;
    margin-left: 35px;
    margin-top: 25px;
    height: 270px;
    overflow: hidden;
}
.facecare_topsectionL_right2 img{
    transition: transform .5s ease;
} 
.facecare_topsectionL_right2 img:hover{ 
    transform: scale(1.5);
} 
    .facecare_topsectionsec{display: flex; margin-top: 20px;} 
    .facecare_topsectionBottom{display: flex; margin-top: 20px;}
    .facecare_topsectionsecLeft{width: 32%;    margin-top: 20px;    margin-right: 35px; height: 280px; overflow: hidden;}
    .facecare_topsectionsecmiddle{width: 23%;    margin-right: 35px;}
    .facecare_topsectionsecLeft img{transition: transform .5s ease;}
    .facecare_topsectionsecLeft img:hover{transform: scale(1.5);}
    .facecare_topsectionsecmiddle img{opacity: 0.6;}
    .facecare_topsectionsecright{width: 37%; margin-top: 20px; height: 324px; overflow: hidden;}
    .facecare_topsectionsecright img{transition: transform .5s ease;}
    .facecare_topsectionsecright img:hover{transform: scale(1.5);}
    .facecare_topsectionBottom-left{width: 35%;}
    .facecare_topsectionBottom-middle{width: 27%; margin-left: 100px; opacity: 0.6;}
    .facecare_topsectionBottom-right{width: 23%;    margin-left: 25px; opacity: 0.6;margin-top: 220px;}
    .facecare_topsectionBottom-left .facecare_topsectionBottom-leftOne img{
        transition: transform .5s ease;
    }
    .facecare_topsectionBottom-left .facecare_topsectionBottom-leftOne img:hover{
        transform: scale(1.5);
    }
    .facecare_topsectionBottom-left .facecare_topsectionBottom-lefttwo{margin-top: 60px;
        position: relative;
        left: 40px;
    height: 306px;
overflow: hidden;}
    .facecare_topsectionBottom-left .facecare_topsectionBottom-lefttwo img{ transition: transform .5s ease;}
    .facecare_topsectionBottom-left .facecare_topsectionBottom-lefttwo img:hover{ transform: scale(1.5);}
    .facecare_topsectionBottom-leftOne{

    overflow: hidden;
margin-left: 30px;
margin-top: 5px;
height: 283px;
}
.mask-market-movingframe-n.However-n .mask-market-movingframe-inner-n{    background: #f4b3b9;}
.mask-market-movingframe.However .mask-market-movingframe-inner p{color: #fff;margin-top: 9%;
    width: 85%;}
    .mask-market-movingframe-inner_cont-n ul{    list-style-type: none; margin-top: 25px;}
    .mask-market-movingframe-inner_cont-n ul li{line-height: 33px;
        font-weight: bold;
        font-size: 17px;
    }  
    .mask-market-movingframe-inner_cont-n ul li span{font-size: 23px;
        font-weight: bold;
    }
    .cream .facecarebanner video{width: 100%;}
    .mask-market-movingframe-inner-bu p{width: 50%;
        text-align: center;
        font-size: 18px !important;
        margin: 0 auto;} 
    .mask-market-movingframe-bu{ background-image: url('../src/lab/mask-bottom.jpg');padding: 350px 0px 100px 0px;
            background-position: top;
            background-size: cover;}     
.mask-market-movingframe.However.organic-products .mask-market-movingframe-inner{background: #b4add7;margin-bottom: 30px;}
.mask-market-movingframe.However.organic-products .mask-market-movingframe-inner p{color:#000;margin-top: 11%;    margin-left: 10%;}
.mask-market-frame.thik{ background-image: url('../src/lab/Facecare4.jpg'); padding: 100px 0px;}
.mask-market-frame.thik .mask-market-frame-inner{  
    width: 60%;
    text-align: center;
    background: #ffffff7d;
    color: #000;
    padding: 20px 10px;  
}
.pride-formlation-maskframe{margin-top: 20px; margin-bottom: 20px;}
.pride-formlation-maskframe p{    
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
}
.contact-us-page video{width:100%;margin-top: 20px;}
.pride-formlation-maskframe h4{    letter-spacing: 2px; color: #7c5341; text-align: center;    font-size: 28px; font-weight: bold;}
.creamdrivensection ~ .pride-formlation-maskframe h4{font-size: 20px;}
.mask-market-frame.thik .container{width: 100%; max-width: 100%;}
.mask-market-frame{
    background: url('../src/lab/Facecare2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0px;
    background-attachment: fixed;
    margin-top: 20px;
}
.face-lotion-firstsection{
    background: url('../src/lab/Facelotion2.jpg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px;
    background-attachment: fixed;
    margin-top: 20px;
}
.lotion-market-movingframerange-product-boo{
     background: url('../src/lab/Facelotion7.jpg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0px;
    background-attachment: fixed;
    margin-top: 30px;
}
.ultra-lightbottom-lotion{margin-top: 40px;}
.ultra-lightbottom-lotion ul{padding: 0px; list-style-type: none;}
.ultra-lightbottom-lotion ul li span{ 
    font-weight: bold;
    font-size: 16px;
    position: relative;
    top: 2px;
    left:15px;
}
.ultra-lightbottom-lotion ul li{margin-bottom: 20px;}
.ultra-lightbottom-lotion ul li img{width:40px;}
.lotion-market-movingframerange-product-boo p{
    background: #ffffffbd;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    font-size: 18px !important;
    padding: 20px 10px;
}
.face-lotion-firstsection-inner{ width:30%;
    padding: 20px 20px;} 
    .lotion-market-movingframe-inner{background: #6d6a49;
        margin-top: 20px;
    }
    .con-sci_lotionframe p{
            margin-top: 40px!important;
            margin: 0 auto;
            width: 90%;
            margin-bottom: 30px;
        }
    .con-sci_lotionframe h4{
        text-align: center;
        color: #5051a4;
        font-weight: bold;
    }
    .lotion-market-movingframenn{position: relative;}
    .lotion-market-movingframenn img{position: absolute; top:0px; width: 450px;}
    .lotion-market-movingframenn p{line-height: 33px !important;
        font-size: 17px !important;}
    .facelotionpage .face-lotion-firstsection-inner p{    font-weight: bold; line-height: 36px !important;}
.lotion-market-movingframe-innernn{    
    background: #f0a379;
    padding: 60px 30px;
    width: 70%;
    float: right;
    padding-left: 11%; 
}
.mask-market-frame-inner{
    background: #000000a1;
padding: 90px 20px;
color: #fff;
}
.mask-market-frame.ascend .container{width: 100%; max-width: 100%;padding:0px; }
.mask-market-frame.ascend .container .row{margin: 0px;}
.mask-market-frame.ascend .container .row .col-md-4{    padding: 0px;}
    .facecare_main-page p{ font-size: 16px; line-height: 29px;}
    .facecare_main-page p:first-letter {
        text-transform: capitalize;
        font-size: 20px;
        font-weight: bold;
      }      
    .facecare_main-page p span.high{    font-weight: 500;
        font-family: BRLNSR;
        font-size: 17px;}
        .mask-market-movingframe-inner{background: #ec1483;    margin-top: 20px;}
        .mask-market-movingframe-inner_cont{
            margin-top: 25px;
        }
        .mask-market-movingframe.mask .col-md-5{padding-left: 0px;}
        .mask-market-movingframe.mask .mask-market-movingframe-inner_cont p{
            color: #fff;
            margin-top: 43px;
            font-size: 17px;
            line-height: 36px;             
        }
        .nastyche-frame-mask-border1{    width: 50%;
            border-bottom: 5px solid #c2b1a1;
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .nastyche-frame-mask-border2{    
            width: 50%;
            border-bottom: 5px solid #c2b1a1;
            margin-top: 20px;
            margin-bottom: 20px;
            float: right;
        }
        .nastyche-frame-mask-cont{    width: 50%;margin: 0 auto;}
        .mask-market-movingframe .container{    width: 100%;
            max-width: 100%;}
            .mask-market-movingframe .container .col-md-9{ padding-left: 10%;     margin-top: 3.5%;}
            .lotion-market-movingframe-inner .container{    width: 100%;
                max-width: 100%;}
                .lotion-market-movingframe-inner_cont p{    margin-top: 130px;color:#fff;  }
                .lotion-market-movingframe.range-product .lotion-market-movingframe-inner{background: #ded8e6;  }
                .lotion-market-movingframe.range-product .lotion-market-movingframe-inner_cont p{ margin-top: 80px; color: #000;}
                .Middel-banner-redfacelotion{margin-top: 20px;}
                .high-water_framefacelotion{position: relative;}
                .high-water_framefacelotion-top-frame.left{background-color: #6eabc7;width: 21.5%;}
                .high-water_framefacelotion-top-frame.left2{background-color: #732f22;  width: 24.5%;}
                .high-water_framefacelotion-top-frame.left3{background-color: #ece2d6;  width: 29.9%;}
                .high-water_framefacelotion-top-frame.left4{background-color: #b7ada3;  width: 24.1%;}
                .high-water_framefacelotion-top{width: 100%;    display: flex; }
                .high-water_framefacelotion-top-frame{height: 240px; width: 25%;}
                .high-water_framefacelotion-bottom{top: 25%;
                    position: absolute;
                    width: 84%;
                    background: #ffffffbf;
                    left: 8%; 
                    padding: 15px;}
                    .lotion-market-movingframe.range-product .lotion-market-movingframe-inner_cont.popular.n p{    color: #000;}
                    .lotion-market-movingframe.range-product .lotion-market-movingframe-inner_cont.popular.n.facewash p{font-size: 18px;
                        line-height: 35px;
                        margin-top: 130px;}
     .lotion-market-movingframe.range-product .lotion-market-movingframe-inner.popular{background-color: #0b0b0b;}
     .lotion-market-movingframe.range-product .lotion-market-movingframe-inner.popular.n{background-color: #d5aa99;}
     .lotion-market-movingframe.range-product .lotion-market-movingframe-inner.popular.growth{
        margin-top: 0px;
        margin-bottom: 30px;
        background-color: #070709;
    }
     .lotion-market-movingframe.range-product .lotion-market-movingframe-inner_cont.popular p{color: #fff;    margin-top: 100px;         }
 .facelotion_heading h2{
    
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;    
    font-size: 36px;
}
.facelotion_heading.facecare h2{    font-size: 43px;
    color: #FFF;
    margin-top: 5px;}
    .mask-market-frame.face-wash {
        background: url('../src/lab/facewash2.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 150px 0px;
        background-attachment: fixed;
        margin-top: 20px;
    }
    .productsinfusedwith-bodycare_frame{ background: url('../src/img/Bodycare8.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 40px 0px 240px 0px;
        background-attachment: fixed;
        margin-top: 0px;}
    .mask-market-frame.ascend{background: url('../src/lab/facewash3.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0px 0px;
        background-attachment: fixed;
        margin-top: 0px;}
        .facelotion_heading.facewash h2{color:#205634;}
        .lotion-market-movingframe-inner.herbs{    background-color: #e3cfd1; margin-bottom: 30px;}
        .lotion-market-movingframe-inner.herbs .lotion-market-movingframe-inner_cont p {
            margin-top: 100px;
            color: #000;
        }
        .current-tendsfacewash{
            background: #8d5a45;
            margin-top: 220px;
            margin-bottom: 40px;
            padding-bottom: 35px;
        }
        .tendsfacewash-bottom{    margin-top: -120px;    color: #fff;}
        .tendsfacewash-top img{
            top: -170px;
            width: 50%;
            position: relative;
            margin: 0 auto;
        }
            .mask-market-frame.face-wash .mask-market-frame-inner p{    width: 80%;
                margin: 0 auto;}
                .bodycareNewpageVideo video{width: 100%; margin-top: 20px;}
    .bodycareheading img{    
        margin: 0 auto;
        width: 350px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .bodycare-industry-new .container{position: relative;}
    .bodycare-industry{
        background: #f78b28;
        text-align: center;
        padding: 20px 0px 10px 0px;
        height: 90px;
        width: 50%;
        float: right;
    }
   .bodycare-industry-new p {
        position: absolute;
        top: 18px;
        font-size: 17px;
        padding: 1px 30px;
        text-align: center;
    }  
.bodycare-industry-Globalization-top{margin: 0 auto;
    width: 90%;
    margin-top: 25px;}
    .bodycare-industry-Globalization-bottom .col-md-4:nth-last-child(1){margin-top: -80px;}
    .bodycare-industry-Globalization-bottom .col-md-4:nth-last-child(2){margin-top: -40px;}
    .bodycare-industry-Globalization-bottom .col-md-4:nth-last-child(3){}
    .bodycare-industry-Globalization-bottom{margin-top: 50px;}
    .stigma-bodycare{background: #fff; margin-top: 30px;margin-bottom: 30px;}
    .stigma-bodycare .container{ width: 100%; max-width: 100%; }
    .stigma-bodycare-inner_cont{    margin-top: 60px;}
    .stigma-bodycare.laboratoire{    
        margin-top: 0px;
        background: #000000;
       color: #fff;
}
.immencepride-bodycare{    margin-bottom: 70px; margin-top: 30px;}  
.immencepride-bodycare p{    padding-left: 50px;
    font-size: 18px;
    line-height: 32px;}  
.immencepride-bodycare .container{ position: relative; }  
.immencepride-bodycare .bodycare-industry2{
background: #d7b899;
height: 110px;
width: 45%;
z-index: -9;
margin-top: -100px;
}
.focused-bodycare{margin-bottom: 50px;}
.focused-bodycare .focused-bodycare-heading h3{
color: #7f4c37;
font-weight: bold;
font-size: 25px;
text-align: center;
} 
.focused-bodycare-bottom ul{    
    padding: 0px;
    list-style-type: none;
}
.focused-bodycare-bottom ul li{    
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
    padding:5px 5px;
}
.focused-bodycare ~ .productsinfusedwith-bodycare_frame p{    font-size: 18px;
    line-height: 35px;}
.focused-bodycare-bottom ul.first-body-care li:nth-child(even):before{
    content: "";
    position: absolute;
    top: 0px;
    left:-36px;
    z-index: 1;
    border: solid 18px transparent;
    border-right-color: #b6988d;
}
.focused-bodycare-bottom ul.second-body-care {}
.focused-bodycare-bottom ul.first-body-care{width: 80%;}
.second-body-care4{ margin-left: 11%;}
.focused-bodycare-bottom ul.second-body-care li:nth-child(even):before{
    content: '';
    position: absolute;
    top: 18px;
    right:-18px;     
    z-index: 1;
    border-right-color: #b6988d;
    transition: all .2s ease-out;
transform: translateY(-50%);
border-left: 18px solid #b6988d;
border-top: 18px solid transparent;
border-bottom: 18px solid transparent;
}

.focused-bodycare-bottom ul li:nth-child(even) { background: #b6988d;position: relative; }
.focused-bodycare-bottom{margin-top: 50px;} 
.focused-bodycare-border{
    border-bottom: 2px solid #7f4c37;
    width: 40%;
    margin: 0 auto;
    margin-top: 15px;
}
.bodycareNewpageVideo-right video{width: 100%;}
.stigma-bodycare-inner img{    
    left: 30px;
    position: relative;
}
.bodycare-girl-page img{visibility: hidden;}
.bodycare-girl-page{background: url(../src/img/Bodycare6.jpg);background-attachment: fixed; background-size: 40%;
background-repeat: no-repeat;}

.col-md-6.stigma-bodycare-inner_cont{padding-left:30px;height: 430px; border-left:2px solid #000;margin-top: 40px; padding-top: 80px;}
.col-md-6.stigma-bodycare-inner_cont p{
line-height: 40px;
font-size: 17px;
}
.been-market-long-allbodycare-middle{
width: 70%;
background: #eef0c9;
padding:30px 15px;
}
.been-market-long-allbodycare img{
float: right;
margin-top: -162px;
width: 400px;
margin-right: 180px;
margin-bottom: 30px;             
}
.been-market-long-allbodycare-middle p{ margin-bottom: 5px; font-size: 18px; }
.been-market-long-allbodycare-middle p:first-letter {font-weight: normal;font-size: 16px;}
.been-market-long-allbodycare-middle p:nth-child(1){padding-left: 25%;}
.been-market-long-allbodycare-middle p:nth-child(2){padding-left: 15%;}
.been-market-long-allbodycare-middle p:nth-child(3){padding-left: 5%;}
.been-market-long-allbodycare{    margin-bottom: 30px;}
.stigma-bodycare-inner video{ width: 100%;     display: block;}
.stigma-bodycare.laboratoire .stigma-bodycare-inner_cont{margin-top: 135px;}
.productsinfusedwith-bodycare_frame-top{    
float: right;
background: #ffffffbf;
width: 49%;
padding: 20px;
}
.productsinfusedwith-bodycare_frame-bottom{
float: right;
background: #ffffffbf;
width: 43%;
padding: 20px;
margin-top: 15px;
}
.productsinfusedwith-bodycare_frame .row{    margin: 0px;}
.Exfoliation-market-frame{    background: url(../src/img/Exfoliation2.jpg);
background-position: center;
background-repeat: no-repeat;
background-size: 33%;
padding: 150px 0px;

margin-top: 0px;}
.Exfoliation-market-frame-inner p{   
    background: #fed45c;
    color: #000;
    padding: 20px;
    width: 85%;
    margin: 0 auto;
}
.Modern-Exfoliation h3{
    font-weight: bold;
    text-align: center;
    color: #503c62;
    margin-top: 40px;
}
.Exfoliation-Accordingly.modern .container .col-md-3{
    padding: 10px;
}
.Exfoliation-market-movingframe-inner_cont-n-left{background: url(../src/img/Eback.jpg);color: #fff;}
.Exfoliation-market-movingframe-inner_cont-n p{}
.Exfoliation-market-movingframe-inner_cont-n-left h4{      
    font-weight: normal;
    margin-top: 150px;
    line-height: 42px; 
}
.Exfoliation-market-movingframe-inner_cont-n-left h4 span{font-weight: bold;
    font-size: 36px;
}
.Exfoliation-market-movingframe-inner_cont-n{background: url(../src/img/Eback2.jpg);padding: 30px;}
.Exfoliation-market-movingframe-inner_cont-n img{    margin: 0 auto;width: 370px; }
.Exfoliation-market-movingframe-inner_cont-n p{
    font-weight: bold;
    margin: 0 auto;
    font-size: 19px;
    width: 90%;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.crucial-step-bodycare-per-mg-right_frame{display: flex;
    width: 100%;}
.crucial-step-bodycare-per-mg-right_frame-inner{}
.crucial-step-bodycare-per-mg-right_frame-inner.three img:nth-child(1){
    margin-top: -40px;
    margin-left: -5px;
    width: 85%;
}
.crucial-step-bodycare-per-mg-right_frame-inner.three img:nth-child(3){
    width: 65%;
}
.crucial-step-bodycare-per-mg-right_frame-inner.one img:nth-child(2){
    width: 90%;
    margin-left: 10%;
}
.crucial-step-bodycare-per{text-align: center;}
.crucial-step-bodycare-per h4{    
        margin-top: 30px;
        color: #733b18;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 80px;
    }
    .cof-sumainframe{
        
line-height: 30px;
        display: inline-block;
        background: #fdde50;
        width: auto;
        padding: 15px 25px 5px 5px;
        box-shadow: 1px 1px 5px #0000008c;
    }
    .crucial-step-bodycare{ margin-bottom: 20px;}
    .cof-sumainframe.two{       
background: #8bd5e2;
margin-top: -50px;
z-index: 999;
position: relative;
left: 180px;
    }
.crucial-step-bodycare-per span{    
    font-weight: 500;
    font-family: BRLNSR;
    font-size: 18px;
    color: #4c2a74;
}
    .Exfoliation-Accordingly{    margin-top: 40px;
        margin-bottom: 30px; background: #a7dde7;}
        .Exfoliation-Accordingly.modern{background:#b4bce0;}
        .Exfoliation-Accordingly.modern .Exfoliation-Accordingly-right{margin-top: 140px;
            font-weight: 500;}
.Exfoliation-market-movingframe-b-n .container{width: 100%; max-width: 100%;}
        .Exfoliation-Accordingly .Exfoliation-Accordingly-right{    margin-top: 50px;
        }
        .Exfoliation .facelotion_heading p{text-align: center;
            font-weight: 500;}
        .Exfoliation-Accordingly .container{width: 100%;max-width: 100%;}
        .Exfoliation-Accordingly .container .col-md-5{padding-left: 0px;}
        .Exfoliation-Accordingly .container .Exfoliation-Accordingly-right p:nth-child(2){margin-top: 80px;}
        .crucial-step-bodycare p{margin-top: 45px;}
        .crucial-step-bodycare-img .crucial-step-bodycare-img-middle{display: flex;}
        .crucial-step-bodycare-img-middle3{ margin-right: 80px; }
        .crucial-step-bodycare-img-middle2{ margin-top: -200px; }
        .crucial-step-bodycare-img-middle1{    margin-left: 60px;}
        .Exfoliation-market-movingframe-inner .container{width: 100%; max-width: 100%;   margin-top: 50px; padding: 0px; background-color: #000;}
        .Exfoliation-market-movingframe-inner .container .row{margin: 0px;}
        .Exfoliation-market-movingframe .col-md-4{padding-right: 0px;}
        .Exfoliation-market-movingframe .Exfoliation-market-movingframe-inner_cont p{
            color: #fff;
            margin-top: 55px;
        }
        .Exfoliationbottomvideo video{width: 100%; margin-top: 50px;}
        .Exfoliation-market-movingframebottom-cont{    margin-top: 30px;
            margin-bottom: 50px;
        }
        .facecare_main-page.Exfoliation{margin-top: 20px;}
        .facecare_main-page.serums .facelotion_heading h2{font-weight: bold;
            font-size: 30px;
            color: #805d25;}
            .serums-market-frame-inner2{background: #fbd0da;
                width: 60%;
                margin: 0 auto;}
                .serums-market-frame-inner2 p{  text-align: center;
                    padding: 30px;}
        .row_frem_makenewgirl{position: relative;margin-top: 30px;}
        .row_frem_makenewgirl .row_frem_makenew2{
            position: absolute;
            bottom: 50px;
            color: #fff;
            width: 82%;
            left: 6%;
        }
        .row_frem_makenewBo.new{   
            text-align: center;
            margin-top: 35px;
        }
        .lipsp-makeuppage{margin-top: 60px; margin-bottom: 40px;}    
        .row_frem_makenewgirl .row_frem_makenew2 p{  
            line-height: 35px;   }
        
        
        .Face-serums-elixir-frame-middle2{
            width: 50%;
            height: 100%;
            position: absolute;
            background: #cbe2c6;
            margin-left: 50%;
            top: 0px;
            right: -20px;             
    }
    .Face-serums-elixir-frame-middle img{
            width: 200px;
            position: absolute;
            right: -60px;
            bottom: -95px;
        }  
        .Face-serums-elixir-frame-middle{
            position: relative;
            width: 90%;
            margin: 0 auto;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;
            margin-bottom: 100px;
        }
        .serum-market-movingframe-inner_contnew-middle{display: flex;}
        .serum-market-movingframe-inner_contnew-right ul{
            background: #ebcfe4;
            color: #000;
            padding: 30px 40px;
            display: inline-block;
            line-height: 32px;
            margin-top: 30px;
        }
        .serum-market-movingframe-Organic2.facesurum img{margin-top: -120px;}
        .serum-market-movingframe-inner_contnew-middle img{    
            width: 65px;
        }
        .serum-market-movingframe-inner_contnew-middle h4{ 
            color: #8e278f;
            font-weight: normal;
            margin-top: 120px;
            margin-left: 20px;
            line-height: 40px;

        }
        .serum-market-movingframe-inner_cont-n p{
            text-align: center;
            font-size: 19px;
             margin-top: 180px;
        }
        .serums-market-frame-inner-market-drivennew{margin-top: 40px;}
        .serums-market-frame-inner-market-drivennew .second-new{    margin-top: 120px;}
        .facecare_main-page.serums{margin-top: 20px;}
        .serums-market-frame{ background: url(../src/img/Serums2.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 100px 0px;
           
            margin-top: 20px;}
            .serums-market-frame-inner{ background: #ffffffc7;
                color: #000;
                padding: 20px;}
                .serums-market-frame.market-driven{ background: url(../src/img/Serums3.jpg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    padding: 100px 0px;
                    background-attachment: fixed;
                    margin-top: 20px;}
                .serums-market-frame-inner-market-driven p{background: #fff;padding: 50px 30px;}
                .serums-market-frame.market-driven .second{
                    margin-top: 70px;
                }
                .Face-serums-elixir-frame{    margin-top: 50px;}
                .Face-serums-elixir-frame p{position: relative;}
                .Imageframefacesurum{margin-top: 50px;text-align: center;}
                .serum-market-movingframe{background-color: #fec128; margin-top: 50px; }  
                .serum-market-movingframe .container{}
                .serum-market-movingframe .serum-market-movingframe-inner_cont{    margin-top: 85px;}
                .serum-market-movingframe.Organic{background-color: #fafaf8;margin-top: 0px;margin-bottom: 50px;}       
                .serum-market-movingframe.Organic .col-md-3{padding-left: 0px;}
                .Imageframefacesurum-middle{display: flex;}
                .Imageframefacesurum-middlel.middle{ position: relative; top: -13px;     box-shadow: 0px 0px 10px #00000063;}   
                .serum-market-movingframe.Organic{margin-top: 30px;}
                .cream-market-frame{ 
                    background: url(../src/img/Cream2.jpg);
                    background-position: left;
                    background-repeat: no-repeat;
                    background-size: 60%;            
                    padding: 100px 0px;
                    margin-top: 20px;
                    display: inline-block;
                    width: 100%;
            }
            .Cream-popular-forgro-frame-footer{
                background: url(../src/img/Cream9.jpg);
                background-position: left;
                background-repeat: no-repeat;
                background-size: cover;            
                padding: 30px 0px 180px 0px;
                margin-top: 20px;
                display: inline-block;
                width: 100%;
            }
            .Cream-popular-forgro-frame-footer p{    color: #fff;
                font-size: 18px;
                width: 30%;
                align-content: space-between;
                text-align: center;
                margin-left: 2%;
                margin-top: 3%;}
            .Cream-popular-forgro-ul-list{
                background: url(../src/img/Cream7.jpg);
                background-position: left;
                background-repeat: no-repeat;
                background-size: cover;            
                padding: 30px 0px;
                margin-top: 20px;
                display: inline-block;
                width: 100%;
                height: 500px;
                overflow: hidden;
            }
            .Cream-popular-forgro-ul-list ul{
                list-style-type: none; 
                padding: 0px; 
                display: inline-block;        
                margin-bottom: 10px;
                top: 0px;
                position: absolute;
                
            }
            .Cream-popular-forgro-ul-list ul li{    
                    margin-bottom: 10px;
                    background: #fff;
                    text-align: center;
                    padding: 7px 10px;
                    font-weight: bold;
                    font-size: 15px;
                    width: fit-content;
            }
            .Cream-popular-forgro-ul-list ul li:nth-child(2){position: relative;
                left: 190px;
                top: 80px;
                width: fit-content;}
                .Cream-popular-forgro-ul-list ul li:nth-child(3){
                    position: relative;
                    left: 190px;
                    top: 290px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(4){
                    position: relative;
                    left: 450px;
                    top: 120px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(5){
                    position: relative;
                    left: 640px;
                    top: -170px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(6){
                    position: relative;
                    left: 750px;
                    top: -100px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(7){
                    position: relative;
                    left: 750px;
                    top: 50px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(8){
                    position: relative;
                    left: 1050px;
                    top: -320px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(9){
                    position: relative;
                    left: 1130px;
                    top: -280px;
                    width: fit-content;
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(10){
                    position: relative;
                    left: 1124px;
                    top: -154px;
                    width: fit-content;
                    
                }
                .Cream-popular-forgro-ul-list ul li:nth-child(11){
                    position: relative;
                    left: 1020px;
                    top: -40px;
                    width: fit-content;
                    
                }
            .Cream-popular-forgro-ul-list .container{width:100%; max-width: 100%;}
                    .cream-market-frame-inner{background: #fff;padding: 30px 25px; float:right;
                    width: 50%;}
                    .creamdrivensection-middle p{
                        z-index: 999;
                        line-height: 35px;
                        background: #fff;
                        width: 90%;
                        font-size: 17px;
                        position: relative;
                        margin-left: 22%;
                        padding: 50px 20px;
                    }
                    .creamdrivensection .col-md-4 img{    position: relative;
                        margin-top: -100px;}
                    .creamdrivensection-middle{margin-top: 110px;}
                    .creamdrivensection .container{width:100%; max-width: 100%;}
                    .creamdrivensection{
                        margin-top: 0px;
                        margin-bottom: 50px;
                    } 
                    
                    .cream-market-frameh.market-driven{    background: #000;
                        color: #fff;
                    } 
                    .cream-market-frame-inner-market-driven .container{width:100%;max-width: 100%;}
                    .cream-market-frame-inner-market-driven .container .second{margin-top: 86px;}
                    .Cream-popular-forgro-frame{background-color: #6ecbdc;}
                    .Cream-popular-forgro-frame .right-cont{ margin-top: 80px;}   
                    .Cream-popular-forgro-frame.loaded{background-color: #f58b97;}
                    .makeup_bottom_slider_section{}
                    .makeup_bottom_slider_section h3{
                        font-size: 36px;
                        color: #a36f42;
                        text-align: center;
                        margin-bottom:30px;
                        font-family: cambria;
                        margin-top: 60px;
                    }
                    .serums.serumspage{background: url(../src/lab/serumsbg.jpg);}
                    .makeup_bottom_slider_section{margin-bottom:50px;}  
                    .makeup_bottom_slider_section-middle{margin: 0 auto; width: 80%; position: relative;}
                    .makeup_bottom_slider_section-middle .slick-prev, .slick-next{width: 35px; height: 70px;z-index: 9999999;background-color: #000 !important;}
                    .makeup_bottom_slider_section-middle .slick-prev {
                        left: -35px;
                    }
                    .makeup_bottom_slider_section-middle .slick-next {  
                        right: -35px;
                    }
                    .makeup_bottom_slider_section-middle .slick-slider {
                        overflow: initial;
                    }
                    .makeup_bottom_slider_section-middle .stf__parent{}
                    .makeup_bottom_slider_section-middle .makeup-bottonarrow button{margin-right: 1px;
                        background: #414141;
                        border: 0px;
                        padding: 20px 10px 15px 7px;}
                        .makeup_bottom_slider_section-middle .makeup-bottonarrow button span{color: #fff;
                            font-size: 35px;
                            line-height: 0px;}
            .container.makeup-bottonarrow{   
                top: 0px; height: 100%;}
                .makeup_bottom_slider_section-middle .makeup-bottonarrow button.left{position: absolute;
                    left: -50px;
                    bottom: 50px;}    
                .makeup_bottom_slider_section-middle .makeup-bottonarrow button.right{position: absolute;
                    bottom: 50px;
                    right: -50px;}
                    .makeup_bottom_slider_section_middle{ overflow: hidden;}
                    .mask-market-frame-inner-new-right{
                        margin-top: 80px;
                    }
                    .mask-market-frame-inner-new-right h4{
                        color: #124b4f;
                        text-align: center;
                        font-size: 36px;
                        margin-bottom: 50px;
                    }
                    .manu-pro-middle-arr-facewash-top{
                        text-align: center;
                        margin-top: 40px;
                    }
                   
                    .manu-pro-middle-arr-facewash-top h4{color: #124b4f;
                        margin-top: 30px;
                    } 
                    .middle_frame_dott img{width:35px;margin: 0 auto;}
                    .manu-pro-middle-arr-facewash-bottom{margin-top: 40px;
                        margin-bottom: 40px;}
                    .manu-pro-middle-arr-facewash-bottom .cont-gelcle h5{    color: #2c8a92;
                        font-size: 19px;} 
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.one{text-align: right;
                            margin-right: -90px;
                        }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.two{
                            margin-left: -90px;
                        }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(1){    margin-top: 110px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(2){    margin-top: 110px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(3){    margin-top: 110px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(4){    margin-top: 100px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(1){    margin-top: 188px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(2){    margin-top: 90px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(3){    margin-top: 115px;  }
                        .manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(4){    margin-top: 115px;  }
                        .mask-market-frame-new.face-wash-new .container{    max-width: 100%;
                            width: 100%;}
               .mask-market-frame-new.face-wash-new .container .col-md-5{padding-left: 0px;}
               .facewashmainpage p{ font-size: 17px; line-height: 33px; }
               .facemaskpage p{font-size: 17px; line-height: 33px;}
               .serums.serumspage p{font-size: 17px; line-height: 33px;}
               .creampage p{font-size: 17px; line-height: 33px;}
               .Exfoliationpage p{font-size: 17px; line-height: 33px;}
               
               .facelotionpage .lotion-market-movingframenn p{font-size: 18px !important; line-height: 37px !important;}
               .facelotionpage .lotion-market-movingframe-innerboo  p{font-size: 20px !important;}
               .facelotionpage .lotion-market-movingframe-innerboo  p span.high{font-size: 20px !important;}
               .facelotionpage .con-sci_lotionframe p{font-size: 19px;}
               .facelotionpage .con-sci_lotionframe p span.high{font-size: 19px;}
               .bodycarepageadd p{font-size: 17px;}
               .drop_menuheader{    
                position: absolute;
                left: 162px;
                background: #fff;
                top: 0px;
                display: none;
                list-style-type: none;
                padding: 0px;
                width: 180px;
                }
            .dropdown-content a:hover .drop_menuheader{display:inline-block;}
            .babycarepageframe .top_head_hair-heading p{text-align: center;}
            .babycarepageframe .top_head_hair-heading img{width: 250px;}
            .babycarepageframe .babycaresales{
                background: url(../src/lab/babycare3.jpg);    
                display: inline-block;
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                margin-top: 40px;
            }

            .babycarepageframe .babycaresales p{ 
                    float: right; 
                    width: 40%;
                    margin-top: 180px;
                    margin-bottom: 230px;
                    margin-right: 10px;
                }
                .babycaresalesrising p{
                    width: 45%;
                    margin-top: 80px;
                    margin-bottom: 80px;
                    margin-left: 10px;
                    background: #ffffffa6;
                    padding: 10px;
                    line-height: 38px;
                }
                .babycaresalesrising{
                    background: url(../src/lab/babycare4.jpg);    
                    display: inline-block;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin-top: 40px;
                    background-attachment: fixed;
                }
                .naturn-skin-frame-babycare video{width: 100%;}
                .naturn-skin-frame-babycare{margin-top: 40px; margin-bottom: 40px;}
                .naturn-skin-frame-babycare p{margin-top: 80px;}
                .produly-heading-main-babycare{margin-top: 50px;}
                .produly-heading-main-babycare h3{
                    line-height: 40px;
                    text-align: center;
                    font-weight: bold;
                    font-size: 24px;
                    margin-bottom: 40px;
                }
                .lastlinebabycareframe{background: #dccbc1; margin-bottom: 40px; margin-top: 20px;}
                .lastlinebabycareframe .row{margin: 0px;}
                .lastlinebabycareframe .row p{
                    width: 80%;
                    margin-top: 100px;  
                    margin-left: 40px;
                    line-height: 45px;
                    font-size: 20px;     
                }
                .babycaresalesrisingnaut{    text-align: center;
                    width: 90%;
                    margin: 0 auto;}
                .produly-heading-main-babycare ul{    
                       
                        list-style-type: none;
                        background: #b8e2f8;
                        padding: 15px 0px;
                        height: 95%;
                        width: 88%;
                    }
                    .produly-heading-main-babycare img{margin-top: 35px;}
                    .produly-heading-main-babycare ul.second{  background: #f6d5e6; float:right;}
                    .produly-heading-main-babycare ul li{
                        font-weight: bold;
                        font-size: 17px;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .hotelamipagestart video{width: 100%;}
                    .hotelpagenew p{font-size: 17px; line-height: 32px;}
                    .hotelpagenew p span.fir{    font-weight: bold;
                        font-size: 24px;}
                    .hotelamipagestart p{margin-top: 60px;}
                    .hotelamipagestart h3{
                        text-align: center;
                        font-size: 32px;
                        font-weight: bold;
                        margin-top: 40px;
                    }
                    .hotelamipagestart{margin-top: 50px;}
                    .hotel-categi-section{background: url(../src/lab/hotel3.jpg);
                        background-size: cover; padding: 80px 20px;}
                    .hotel-categi-section .row{margin: 0px;}
                    .hotel-categi-section ul{    
                            padding: 0px;
                            list-style-type: none;
                        }
                    .hotel-categi-section ul li{    
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 15px;
                    }
                    .cateheading h4{
                        text-align: center;
                        font-size: 30px;
                        font-weight: bold;
                        margin-top: 40px;
                    }
                    .hotelpage-border{
                           margin: 0 auto;
                            margin-top: 40px;
                            margin-bottom: 40px;
                            border-bottom: 2px solid #0000006b;
                            width: 40%;
                        }
                        .hotelamipagestart.spa .helteami-left p{margin-top: 30px;}
                        .hotelamipagestart.spa .helteami-left h3{margin-bottom: 40px;}
                        .hotelamipagestart.tailor{}
                        .hotelamipagestart.tailor .telar-hotel-heading{}
                        .hotelamipagestart.tailor .telar-hotel-heading h4{
                            text-align: center;
                            font-weight: bold;
                            font-size: 30px;
                        }
                        .hotelamipagestart.tailor .telar-hotel-heading p{    
                            text-align: center;
                            margin-top: 0px;
                            margin-bottom: 60px;
                        }
                        .hotelamipagestart.spa .row .col-md-4{margin-left: 0%;}
                        .hotelamipagestart .row .col-md-4{margin-left: 5%;}
                        .hotelamipagestart.tailor{margin-bottom: 50px;}
                        .hotelamipagestart.tailor .helteami-left{margin-top: 100px;}
                        .hotelamipagestart.tailor .helteami-left p{margin-top: 40px;}
                        .branding_new_page p{    font-size: 17px;
                            line-height: 30px;
                        }
                        .branding_new_page .brandingvideobottom p{    
                            margin: 0 auto;
                            width: 90%;
                            margin-top: 35px;
                        }
                        .about-us-new-pageheading{    text-align: center;}
                        .about-us-new-pageheading img{width: 180px; margin: 0 auto; padding-top: 40px;}
                        .about-us-new-pageheading h2{margin-top: 30px;margin-bottom: 40px; font-size: 36px; font-weight: bold;}
                        .about-us-new-pageheading h2 span{    font-weight: normal;
                            font-size: 30px;     font-style: italic;     transform: rotate(
                                -7deg);
                                    display: inline-block;}
                        .about-us-new-pageheading p{}
                        .aboutusnewpage p{font-size: 18px; line-height: 35px;}
                        .aboutusnewpage .abou-left-frame{display: flex;}
                        .aboutusnewpage .abou-left-frame img{  width:130px; margin-right: 40px;}
                        .aboutusnewpage .abou-left-frame p{margin-top: 15px;}
                        .aboutusnewpage{
                            background: url(../src/lab/aboutus9.png);
                            background-attachment: fixed;
                           
                        }
                        .aboutusnewpage video{display: block; width: 100%;}
                        .suteb-frame-aboutus-new{margin-top: 50px;}
                        .suteb-frame-aboutus-new .suteb-frame-aboutus-new-right h3{    
                            text-align: right;
                            font-size: 42px;
                            font-weight: bold;
                            width: 95%;
                            margin-top: 50px;
                        }
                        .suteb-frame-aboutus-new .suteb-frame-aboutus-new-right p{margin-top: 25px;width: 95%;}
                        .suteb-frame-aboutus-new .row{margin-top: 30px;}
                        .suteb-frame-aboutus-new-left-border{    
                               border-bottom: 2px solid #000;
                               width: 90%;
                               margin: 0 auto;
                                margin-bottom: 20px;
                            }
                            .dedicateframe-aboutusnew .suteb-frame-aboutus-new-left img{ 
                                width:90px; 
                                margin-top:-130px;  
                                left:150px; 
                                position: relative;
                            }
                            .row.Green-Manufacturing-aboutusnew{margin-top: 60px; }  
                            .dedicateframe-aboutusnew .suteb-frame-aboutus-new-right p{    
                                padding-left: 124px;
                                    margin-top: 60px;
                                }
                            .dedicateframe-aboutusnew .suteb-frame-aboutus-new-left .suteb-frame-aboutus-new-left-border{
                                position: relative;
                                right: 235px;
                                width: 100%;
                            }
                        .row.Green-Manufacturing-aboutusnew .suteb-frame-aboutus-new-right h3{margin-top: 0px;}
               .clean-innovation-about-is-new-top{    
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                .clean-innovation-about-is-new-big-left{
                    background: #99cfe6;
                    padding: 170px 30px;
                }
                .pro-comped-frame-about-new-left{    
                    background: #b1d790;
                    padding: 40px;
                }

            .pro-comped-frame-about-new-right{
                background: #bbddb2;
                display: inline-block;
                padding: 20px 40px;
                font-weight: bold;
                box-shadow: 0px 2px 11px #0006;
                left: -70px;
                    position: relative;
                    top: -50px;
            }
            .pro-comped-frame-about-new{margin-top: 100px;}
            .pro-comped-frame-about-new-right p{    line-height: 40px;
                font-size: 32px;
            }
            .aboutusnewpage .pro-comped-frame-about-new-right p::first-letter{font-size: 32px;} 
            .about-newlastpara img{        
                width: 60%;
                margin: 0 auto;
                margin-top: 50px;
                margin-bottom: 50px;
             } 
                .clean-innovation-about-is-new-big-left h4{
                    font-size: 35px;
                    font-weight: bold;
                    line-height: 80px;
                    letter-spacing: 1px;
                }
                .clean-innovation-about-is-new-big-right .row{margin-bottom: 15px;}
               .clean-innovation-about-is-new-top h3{ 
                margin-bottom: 20px; 
                text-align: center;
                font-size: 46px;
                font-weight: normal;
                letter-spacing: 5px;
            }
               .clean-innovation-about-is-new-top p{}
               .aboutusnewpage p::first-letter{font-weight: bold; font-size: 20px;}
               .clean-innovation-about-is-new-big-right h5{
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px;
                margin-top: 10px;
              }
              .col-md-6.mobilebrand{
                display: none;
              }
              .reachand_developent_newhomepage h4{
                margin-top: 100px;
              }
              .trustedorganic{
                font-size: 26px !important;
                font-weight: bold !important;
              }
              .footer-logo-last img{

              }
              .ai-readiness-tab-frame{

              }
              .ai-readiness-tab-frame h3{
                font-family: Raleway-ExtraBoldr;
                text-align: center;
                color: #000000;
                font-size: 26px;
                margin-bottom: 30px;
                font-weight: bold;
              }
              .ai-readiness-tab-frame .accordion__item + .accordion__item {
                border: 1px solid #00000047;
            }
            .ai-readiness-tab-frame .accordion__item {
              
                border-radius: 10px;
                padding: 5px 15px;
                border: 1px solid #00000047;
            }
            
            .ai-readiness-tab-frame .accordion__item {
                margin-bottom: 10px;
            }
            .ai-readiness-tab-frame .accordion__button {
                background-color: transparent;
                position: relative;
                font-size: 15px;
                font-family: Raleway-ExtraBoldr;
                padding: 10px;
            }
            .ai-readiness-tab-frame  .accordion__panel {
                padding: 20px;
                animation: fadein 0.35s ease-in;
                padding-top: 0px;
                padding-bottom: 5px;
            }
            .ai-readiness-tab-frame .accordion{
                border: 0px;
              }
              .ai-readiness-tab-frame p {
                font-family: 'Raleway', sans-serif;
                font-size: 16px;
                margin-top: 10px;
            }
            .ai-readiness-tab-frame {
                margin-bottom: 60px;
            }
            .ai-readiness-tab-frame .accordion__button:before {
                float: right;
                margin-top: 6px;
            }
            .Privacy-Policy-main-page h2{
                font-size: 17px;
                font-weight: 600;
                margin-top: 25px;
                margin-bottom: 20px;
            }
            .Privacy-Policy-main-page h4{
                font-size: 17px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 20px;
            }
            .Privacy-Policy-main-page h3{
                font-size: 17px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 20px;
            }
            .Privacy-Policy-main-page{
                margin-bottom: 60px;
            }
            .Privacy-Policy-main-page ul{
                padding-left: 16px;
            }
            .Privacy-Policy-main-page ul li{
                font-size: 15px;
                margin-bottom: 10px;
            }
            .footer-from-frame-bii .metting-contact-us{
                 text-align: left;
                 display: inline-block;
                 margin-bottom: 15px;
            }
            .footer-from-frame-bii .metting-contact-us button{
                margin-bottom: 15px;
                font-size: 12px;
                line-height: 18px;
                border-radius: 0px;
                text-transform: uppercase;
                padding: 10px 12px;
                background: #414141;
            }
            .footer-from-frame-bii .metting-contact-us button:hover{
                background: #fff;
                color: #000;
            }

            /*new page */
            .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h2{
                color: #231f1e;
                font-family: LucidaHandwritingItalic;
                text-align: center;
                margin-bottom: 50px;
                font-size: 42px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h3 span{
                display: block;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h3{
                text-align: center;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart p{
                text-align: center;
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart video {
                width: 75%;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .cateheading{
                text-align: center;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section{
                background: #fff;
                text-align: center;
               
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section-mm{
                width: 80%;
                margin: 0 auto;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section img{
                width: 77.5%;
    float: left;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section p{
                line-height: 22px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section p span{
             font-weight: bold;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section h5{
                font-weight: bold;
                margin-top: 30px;
                font-size: 20px;
            }
            .cateheading p{
                width: 81%;
                margin: 0 auto;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new{
                margin-bottom: 70px;
                margin-top: 20px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new h3{
               
                color: #231f1e;
                font-family: LucidaHandwritingItalic;
                text-align: center;
                margin-bottom: 50px;
                font-size: 46px;
                letter-spacing: 6px;
                line-height: 70px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new h3 span{
  display: block;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new img{
                width: 100%;
                padding-left: 70px;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new p{
                text-align: center;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage  .hotelamipagestart.spa.new p a{
                background-color: #eaeef1;
                padding: 15px 20px;
                color: #000;
                border-radius: 31px;
                font-weight: bold;
            }
            .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section .col-md-5.mobile{
                 display: none;
            }
@media all and (min-width:120px) and (max-width:700px){
    .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section .col-md-5.mobile{
        display: block;
   }
   .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section .col-md-5.desktop{
    display: none;
}
    .hotelpagenew.HotelBG.fragrances-mainpage .bodycareNewpageVideo video {
        margin-top: 0px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h2 {
        margin-bottom: 15px;
        font-size: 28px;
        margin-top: 30px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h3 span {
        display: inline;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart h3 {
        text-align: center;
        font-size: 22px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart p {
        text-align: center;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart video {
        width: 100%;
        margin-top: 20px;
    }
    .cateheading h4 {
        font-size: 22px;
        margin-top: 30px;
    }
    .cateheading p {
        width: 100%;
        margin: 0 auto;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section-mm {
        width: 100%;
        margin: 0 auto;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotel-categi-section img {
        width: 100%;
        float: none;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart.spa.new img {
        width: 100%;
        padding-left: 0px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart.spa.new h3{
        font-size: 24px;
        line-height: 35px;
    }
    .hotelpagenew.HotelBG.fragrances-mainpage .hotelamipagestart.spa.new h3 span{
        display: inline;
    }
    .footer-left2 ul.footer-right-list ~ a img {
        width: 40%;
        margin: 0 auto;
    } 
    .logo{
        margin-bottom: 12px;
    }
    .reachand_developent_newhomepage h4{
        margin-top: 10px;
      }
.row_branding_top{
    margin-bottom: 0px;
}
.branding_new_page .brandingvideobottom p{
    width: 100%;
}
.row_branding_top .col-md-12.bdex {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    padding-top: 25px;
    padding-bottom: 25px;
}
.left-content-frame p{
    margin: 20px 0px;
}
p.Bring-center-margin{
    width: 100%;
}
.branding-innvotivemain .fisstsect.second .left-content-frame p {
    margin-top: 20px;
}
.bottomreflesh-brand-first-right{
    background: #ffffffc2;
padding: 20px;
margin-top: 0px;
display: block;
width: 100%;
}
.row.bottomreflesh-brand-first.nd.new{
    margin-top: 50px; 
}
.branding_new_pae-left {
    padding: 0px;
    width: 100%;
    margin-top: 25px;
}
.col-md-6.desktopbrand{
    display: none;
}
.col-md-6.mobilebrand{
    display: initial;
  }
.brand-font-number{
    display: none;
}
.bottomreflesh-brand-first img{
    margin-left: 0px;
}
.bottomreflesh-brand-first.nd .bottomreflesh-brand-first-right{
    left: 0px;
}
.clean-innovation-about-is-new-big-left{padding: 10px 30px;}
.about-newlastpara img{width: 95%;}
.pro-comped-frame-about-new{margin-top: 20px;}
.pro-comped-frame-about-new-right{left:0px; top:10px;}
.clean-innovation-about-is-new-big-right .row img{margin-bottom: 5px; margin-top: 5px;}
.clean-innovation-about-is-new-big-right .row .col-md-4{width: 50%;}
.suteb-frame-aboutus-new .row{margin-top: 0px;}
.dedicateframe-aboutusnew .suteb-frame-aboutus-new-left img{margin-top: 0px;}
.suteb-frame-aboutus-new .suteb-frame-aboutus-new-right p{width: 100%; padding-left: 0px; margin-top: 20px;}
.aboutusnewpage .abou-left-frame{margin-bottom: 10px;}
.suteb-frame-aboutus-new { margin-top: 20px;}
.suteb-frame-aboutus-new .suteb-frame-aboutus-new-right h3{width: 100%; text-align: center; font-size: 30px; margin-top: 20px;}
.suteb-frame-aboutus-new .suteb-frame-aboutus-new-right h3 br{display: none; }
.hotelamipagestart.tailor .telar-hotel-heading p{margin-bottom: 0px;}
.hotelamipagestart.tailor .helteami-left{margin-top: 0px;}
.hotelpage-border{margin-top: 20px; margin-bottom: 20px;}
.hotel-categi-section{padding: 40px 20px;}
.hotelamipagestart .row .col-md-4{margin-left: 0px;}
.hotelamipagestart{margin-top: 0px;}
.hotelamipagestart h3{margin-top: 20px;}
.hotelamipagestart p{margin-top: 20px;}
.produly-heading-main-babycare ul{width: 100%;}
.babycarepageframe .babycaresales p{
    margin: 0 auto;
    float: none; 
    width: 95%;
    margin-top: 50px;
     margin-bottom: 50px;
     background: #ffffff96;
     padding: 20px;
    }
    .babycaresalesrising p{width: 95%;}
    .babycaresalesrising{    background-attachment: initial;}
.haircarelast-line p{
    padding-left: 0px;
    background: #ffffff73;
    color: #000;
    padding: 20px;
}
.naturn-skin-frame-babycare{margin-bottom: 10px;}
.babycaresalesrisingnaut{width: 100%;}
.naturn-skin-frame-babycare p{margin-top: 20px;}
.manustoryhaircarepage .manustoryhaircarepage-right ul{float: none; margin-top: 0px;}
.manustoryhaircarepage{background-attachment: initial;}
.bottom_2nd_sect_hair .bottom_2nd_sect_hair-leftnew{margin-top: 10px;}  
.right_hair_cont_pagenew h3{margin-top: 20px;}
.top_head_hair-heading p{width: 100%;}
.top_head_hair-heading p span{padding-left: 0px;}
.mencareproductlist-bottom.lastp p{margin-top: 20px;}
.mencareproductlist-bottom.lastp .col-md-7{padding-left: 15px;}
.mencarebottom-fixedsection{    background-attachment: initial;
    background-size: cover;
}
.mencareproductlist-bottom ul{margin-top: 20px;}
.mencaremiddle-border{    display: none;}
.immenseframemencare{width:100%;}
.mencarerecentyear2 .col-md-8{padding-left: 15px;}
.mencarerecentyear2 .col-md-4{padding-right: 15px;}
.mencarerecentyear2 .col-md-8 p{width: 100%; margin-top: 0px;}
.mencarerecentyear p{margin-top: 20px;}
.mencareskinis {margin-top: 0px;}
.right-imagemencarenew{ background-attachment: initial;}
.bodycare-girl-page{background-attachment: initial;
    background-size: cover;}
.facecare_topsectionsecmiddle{margin-right: 0px;}
.facecare_topsectionBottom-middle{margin-left: 0px;}
.facecare_topsectionBottom-left {  width: 60%;}
.facecare_topsectionBottom-right{margin: 0px;}
.facecare_topsectionBottom-leftOne{height: auto;margin-left: 0px;}
.facecare_topsectionBottom-left .facecare_topsectionBottom-lefttwo{height: auto;margin: 0px; left: 0px;}
.facecare_topsectionL.right { width: 70%; }
.facecare_topsectionsecLeft{height: auto;    margin: 0px; width: 60%;}
.facecare_topsectionsecright{height: auto;width: 50%; margin-top: 0px;}

.facecare_topsectionL_right2{width: 100%; margin-left: 0px;height: 0px;}
.facecare_topsectionL_right1{height: auto;left: 0px;}
.facecare_topsectionL.mi{margin-top: 0px;}
.productsinfusedwith-bodycare_frame{background-attachment: initial;padding: 20px;}
.productsinfusedwith-bodycare_frame-top{width: 100%;}
.been-market-long-allbodycare-middle p{padding-left: 0px !important;}
.second-body-care4{margin-left: 0%;}
.focused-bodycare-bottom ul.first-body-care{margin: 0 auto;}
.focused-bodycare-bottom ul.second-body-care{margin: 0 auto; width: 80%;}
.immencepride-bodycare .bodycare-industry2 {margin-top: -190px; height: 190px;}
.immencepride-bodycare p{padding-left: 0px;}
.been-market-long-allbodycare img{width: 70%; margin: 0px; margin-top: -60px;}
.been-market-long-allbodycare-middle{width: 100%;}
.col-md-6.stigma-bodycare-inner_cont {height: auto; margin-top: 0px; padding-top: 0px;}
.stigma-bodycare-inner img{left: 0px;}

.bodycareNewpage .bodycare-industry-Globalization .container .col-md-4 img{margin: 0 auto;margin-top: 110px;}
.bodycare-industry{height: 200px;}
.bodycareheading img{width: 60%;}
.lotion-market-movingframerange-product-boo{background-attachment: initial;}
.con-sci_lotionframe p{width: 100%;}
.lotion-market-movingframenn img{width: 25%;}
.lotion-market-movingframe-innernn{width: 100%;}
.face-lotion-firstsection{background-attachment: inherit;}
.face-lotion-firstsection-inner{    background: #ffffffc2; width: 100%;}
  .col-md-3 .Exfoliation-Accordingly-left img{margin: 0 auto; width: 60%;}  
.Exfoliation-market-movingframe-inner_cont-n-left{margin-bottom: 20px;}
.Exfoliation-market-movingframe-inner_cont-n-left h4 span{font-size: 25px;}
.Exfoliation-market-movingframe-inner_cont-n-left h4{margin-top: 0px;}
.crucial-step-bodycare-per h4{margin-bottom: 30px;}
.cof-sumainframe.two{margin-top: 20px; left: 0px; margin-bottom: 70px;}
.cof-sumainframe{width: 100%;}
.Exfoliation-Accordingly.modern .Exfoliation-Accordingly-right{margin-top: 20px;}
.Modern-Exfoliation h3{padding:20px}
.Exfoliation-market-frame{background-size: cover;}
.Exfoliation-Accordingly .Exfoliation-Accordingly-right {margin-top: 20px;}
.Exfoliation-Accordingly .container .Exfoliation-Accordingly-right p:nth-child(2){margin-top:0px;}
.Exfoliation-Accordingly .container .col-md-5{padding-left: 15px; padding-top: 5px;}
.Cream-popular-forgro-frame-footer{padding: 20px;}
.Cream-popular-forgro-frame-footer p{width: 100%; margin-left: 0%; padding: 15px;}
.Cream-popular-forgro-ul-list{height: auto;}
.Cream-popular-forgro-ul-list ul{position: relative;}
.Cream-popular-forgro-ul-list ul li{left: 0px !important; top:0px !important;}
.creamdrivensection{margin-bottom: 0px;}
.creamdrivensection-middle p{width: 100%; padding: 0px; margin-left: 0px;}
.creamdrivensection-middle {margin-top: 0px;}
.cream-market-frame{background-size: cover;}
.cream-market-frame-inner{width: 100%; padding: 0px;} 
  .serums-market-frame-inner-market-drivennew .col-md-4.first-new img{width: 70%; margin: 0 auto;}
.serum-market-movingframe-Organic2.facesurum img{margin-top: -40px;}
.serum-market-movingframe-inner_cont-n p{margin-top: 10px;}
.Face-serums-elixir-frame-middle{margin-bottom: 20px;}
.serum-market-movingframe-inner_contnew-right ul{width: 100%;}
.Face-serums-elixir-frame-middle2{right: 0px; width: 100%;}
.Face-serums-elixir-frame{margin-top: 0px;}
.Face-serums-elixir-frame-middle img{width: 40%; right: -20px; bottom: -50px;}
.serums-market-frame-inner-market-drivennew .second-new{margin-top: 20px;}
.serums-market-frame-inner2{width: 100%;}
.pride-formlation-maskframe p{width: 100%;}
.mask-market-movingframe-inner-bu p{width: 100%;}
.mask-market-frame.thik{background-attachment: inherit; padding:50px 0px;}
.mask-market-frame.thik .mask-market-frame-inner{width: 100%;} 
.mask-market-movingframe.mask .col-md-5{padding-left: 15px;}
.mask-market-movingframe-inner_cont{margin-top: 0px;}
.mask-market-movingframe.mask .mask-market-movingframe-inner_cont p{margin-top: 10px;}
.nastyche-frame-mask-cont{width: 90%;}
.mask-market-frame.ascend{background-attachment: inherit;}
.lotion-market-movingframe.range-product .lotion-market-movingframe-inner_cont.popular.n.facewash p{margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(1) {margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(2) {margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(3) {margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(4) {margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one h5:nth-child(5) {margin-top: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.one {text-align: center;margin-right: 0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(1) {margin-top:0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(2) {margin-top:0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(3) {margin-top:0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(4) {margin-top:0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two h5:nth-child(5) {margin-top:0px;}
.manu-pro-middle-arr-facewash-bottom .cont-gelcle.two{margin-left: 0px; text-align: center;}
.mask-market-frame-inner{padding:30px 20px;}
.mask-market-frame-new.face-wash-new .container .col-md-5{padding-left: 15px; margin-top: 20px;}
.mask-market-frame-inner-new-right {margin-top: 10px;}
.mask-market-frame-inner-new-right h4{margin-bottom: 10px;}
.makeup-new-pageAd .b_heading_bo {width: 100%;margin-left: 0%;margin-bottom: 20px;}  
.b_heading_bo_left {margin-top: 10px;padding-left: 0%;}
.b_heading_bo_left p{width: 100%;}
.b_heading_bo_right img{    width: 75%; margin: 0 auto; padding-top: 5px; padding-bottom: 5px;}
.makeup-new-pageAd .b_heading_bo.two{width: 100%;}
.b_heading_bo.two .b_heading_bo_left{padding-left: 0%;}
.makeup-new-pageAd .b_heading_bo.three{width: 100%;margin-left: 0%;}
.b_heading_bo.three .b_heading_bo_left{margin-top: 0px;}
.row_frem_makenewgirl .row_frem_makenew2{    position: relative; color: #000; bottom:0px;    width: 96%;left: 2%;}
.row_frem_makenew2gif .col-md-2 {    width: 33%; margin-bottom: 5%;}
.row.row_frem_makenew2gif{width: 100%;}
.make_new_imgf{background-attachment: inherit;}
.makeup_bottom_slider_section h3{font-size: 30px;}
.makeup_bottom_slider_section-middle .makeup-bottonarrow button span{font-size: 25px;}


.testing_main_frame .raw_metel h4{    padding-top: 5px;padding-bottom: 15px;}
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(1){z-index: 1 !important;}
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(3){z-index: 1 !important;}
.testing_main_frame .row.raw_metel_testing.first .col-md-4:nth-child(2){background: #cff1fa;    z-index: 1 !important;}
.testing_main_frame .row.raw_metel_testing .col-md-4{margin-bottom: 20px;}

.container.raw_metel_testing.first_main.desktop{display: none;}
.container.raw_metel_testing.first_main.mobile{display: block;}
.Packaging_main_page .simply_text_add {                
margin-top: 20px;
margin-bottom: 20px;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(1) .video_Heading h3 {
margin-left: 0px;
font-size: 30px;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(2) .video_Heading h3 {
margin-left: 0px;
font-size: 30px;
}
.Packaging-top-banner_video_middle .col-md-4:nth-child(3) .video_Heading h3 {
margin-left: 0px;
font-size: 30px;
}
.Packaging-top-banner_video_middle .col-md-4{width: 33%;}
.middle_main_pack_pic_frame{background-position: top;
background-attachment: initial;    background-size: 100%;    height: auto;background: #64cdc9;
}
.packaging_new_all_main{ top: 10px;    position: relative;}
.packaging_new_all_clickone.one_6{    right: 0px;top: 0px;}
.packaging_new_all_clickone.one_5{    right: 0px;top: 0px;}
.packaging_new_all_clickone.one_4{    right: 0px;top: 0px;}
.packaging_new_all_clickone.one_3{left: 0px;top: 0px;}
.packaging_new_all_clickone.one_2{left: 0px;top: 0px;}
.packaging_new_all_clickone.one_1{    left: 0px;top: 0px;}
.packing_heading_frame-left h4{    font-size: 19px;}
.packing_heading_frame-left h1{font-size: 42px;}
.packing_heading_frame-center{    margin-left: 0%;margin-top: 20px;}
.bottom_heading_section h1{font-size: 38px;
margin-top: 20px;
margin-bottom: 20px;}
.bottom_heading_section h5{    font-size: 17px;margin-top: 20px;}
.cont_frame_prilabel{margin-top: 25px;}
.product_green_heading span.plus{font-size: 38px;}
.product_green_heading h5{font-size: 17px;}
.product_green_heading h5 span{font-size: 18px;}
.AccordionItem_main_section h4{    line-height: 24px;font-size: 16px;}
.product_list_pri-lable h4.proudly_we {margin-bottom: 25px;font-size: 28px;}
.Marquee_2 {margin-top: 20px;margin-bottom: 30px;}
.Marquee_main_image_frame{    width: 150px;}
.Marquee_1 h3{    margin-bottom: 20px;
font-size: 26px;} 
.bottom_image_effect{padding: 60px 0px;}
.privel-label-newpage .bottom_image_effect p{font-size: 14px;} 
.privel-label-newpage .bottom_image_effect p span{font-size: 20px;}  
.row.product_fl_main-new-bottom-client .col-md-4{width: 50%;}  
.row.product_fl_main-new-bottom-client .col-md-4:nth-child(3){margin-left: 25%;}
.row.bespoke_main_middlennn .col-md-3.padding img{    margin: 0 auto; width: 50%;}
.bespoke_main_bottom_led.Ne img{width: 50%;}
.row.top_main_page_ctstom2 .col-md-4{    width: 50%;}
.row.top_main_page_ctstom2 .col-md-4:nth-child(3){margin-left: 25%;}  
.Packaging-top-banner_video_borrom.desktop{display: none;}
.Packaging-top-banner_video_borrom.mobile{display: block;}
.Packaging-top-banner_video.Desktop{display: none;}
.Packaging-top-banner_video.Mobile{display: initial;}
.bottom_image_effect{background-attachment: initial;}   
.top-video_privel.Desktop{display: none;}
.top-video_privel.Mobile{display: block;}
.video-container_home_paage .home-page_tom_Mobile{display: block;}
.video-container_home_paage .home-page_tom_desktop{display: none;}

.bottom-video_privel.Start_now-.pvt_lbl_mobile{display: block;}
.bottom-video_privel.Start_now-.pvt_lbl_desktop{display: none;}
.top-video_privel.testing_mobile{display: block;}
.top-video_privel.testing_desktop{display: none;}
.top-video_privel.testing_mobile{display: block;}
.top-video_privel.testing_desktop{display: none;} 
.bottom-video_privel.deskto_manu_bot{display: none;}
.bottom-video_privel.mobile_manu_bot{display: block;}  
.top-video_privel.ma_veo_Desktop{display: none;}
.top-video_privel.ma_veo_mob{display: block;}
.GREEN-BRAND-FACTORY ~ a{text-decoration: none !important;}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-middle{display: flex; }
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-right{display: flex;}
.Scrince_come_here_frame p{padding: 0px 10px;}
.Scrince_come_here_frame h4{padding: 0px 10px;}
.new_home_page .lunch_now_buttomframe .speed_frame .iamg_frame_all.new img{    width: 50%;}
.new_home_page .lunch_now_buttomframe .speed_frame img{width: 50%;}
.lunch_now_buttomframe .speed_frame{ padding-top: 10px; padding-bottom: 0px;}
.join_side_new_home_page-right.desk{display: none;}
.join_side_new_home_page p{font-size: 15px;}
.join_side_new_home_page p span{
font-size: 15px;
font-weight: bold;
}
.join_side_new_home_page-right.mob{display: initial;}
.join_side_new_mao.desk{display: none;}
.left_home_page_new_first_cont.NW.Mob{display: initial;} 
.join_side_new_mao.mobile{display: flex;}    
.new_home_page_first_section .row:nth-child(2) .left_home_page_new_first_cont.desktop h3{ display: none;}
.right_img_home_pagenew img{width: 75%;margin: 0 auto;}
.right_img_home_pagenew.fi{    background-image: initial; margin-bottom: 20px;}
.right_img_home_pagenew.nd{    background-image: initial; margin-bottom: 20px;}
.right_img_home_pagenewMob{display: initial;}  
.right_img_home_pagenewDesk{display: none;}  
.live_animalvideo .container--- .img-responsive{
display: initial; 

margin-bottom: 10px;
}  
.live_animalvideo .container--- video{display: none !important;}

.Scrince_come_here_frame img{display: initial;margin-bottom: 30px;}
.Scrince_come_here_frame{    padding-bottom: 10px;}
.video-container_home_paage .home-page_tom_desktop{display: none;}
.video-container_home_paage .home-page_tom_mobile{display: initial;}
.Scrince_come_here_frame video{display: none !important;}
.Main_banner_hb_mid.Mob{display: initial;}
.Main_banner_hb.Desk{display: none !important;}
.reachand_developent_newhomepage{    overflow: hidden !important;}
.right_img_home_pagenew img{visibility: initial;}   
.right_img_home_pagenew.fi {background-position: initial;
background-size: cover;
-moz-background-size: cover;
-webkit-background-size: cover;
-o-background-size: cover;}
.right_img_home_pagenew.nd {background-position: initial;
background-size: cover;
-moz-background-size: cover;
-webkit-background-size: cover;
-o-background-size: cover;}
.join_side_new_home_page .container .col-md-4{    max-width: 50%;    padding-left: 0px;
padding-top: 7%;}
.join_side_new_home_page .container .col-md-8{    max-width: 50%;}
.join_side_new_home_page-middle img{    margin-left: 0px;}
.bottom_con_all_homepagenew {
margin-bottom: 0px;
}
.reachand_developent_newhomepage{padding: 20px 0px;}       
.complete_ready_sell{    margin-top:0px;position: relative;}
.Main_banner_hb_mid{    height: 165px;}
.Scrince_come_here_frame{margin-top: 0px;}
.Main_banner_hb {
margin-top: 2885px;
}
.GREEN-BRAND-FACTORY{top:0px;    position: relative;}
.new_home_page_first_section {
margin-top: 0px;
margin-bottom: 0px;
}
.new_home_page_first_section .row:nth-child(1) .left_home_page_new_first_cont h3 {
margin-top: 20px;
margin-bottom: 20px;
}
.left_home_page_new_first_cont h1{
      margin-bottom: 20px;
    margin-top: 20px;}
.left_home_page_new_first_cont.lift {
width: 95%;
margin: 0 auto;
}
.reachand_developent_newhomepage h4{    font-size: 45px;}
.reachand_developent_newhomepage h4 span {
font-size: 45px;
}
.join_side_new_home_page{padding: 0px 0px;}
.green_side_bottom h5{    font-size: 38px;    margin-bottom: 40px;}

.our_range_sectio_img_frame-top a:nth-child(1) img{width: 60%;}
.our_range_sectio_img_frame-top a:nth-child(2) img{width: 37%;}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-left{     
width: 100%;}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-middle{width: 100%;}
.our_range_sectio_img_frame-bottom .our_range_sectio_img_frame-bottom-right{width: 100%;}
.our_range_sectio_img_frame-bottom-left img { width: 50%; }         
.AccordionItem_main_section_mobile{display:initial;}
.AccordionItem_main_section { margin-bottom: 0px; margin-top: 0px; }
.AccordionItem_main_section form{display: none;}
.product_list_pri-lable ul li:nth-child(6) h5{height: 100px;}
.product_list_pri-lable ul li:nth-child(5) h5{height: 100px;}
.product_list_pri-lable ul li:nth-child(7) h5{height: auto;}
.product_list_pri-lable ul li:nth-child(8) h5{height: auto;}
.product_list_pri-lable ul li {     width: 43%;    }
.product_list_pri-lable ul li h5 br{display: none;}
.product_list_pri-lable ul li h5{word-break: break-word;}
.packaging_main_frame .sustain_right_img.right img{float: none;    margin-top: 0px;}
.packaging_main_frame .sustain_right_img img{    margin-top: 20px;}
.packaging_main_frame .sustain_left ul{
padding: 10px 20px;
}
.top_cont.AUDIO_IDENTITY h4 {
margin-top: 20px;
}
.packaging_main_frame .only_use_frame{widows: 100%;}
.packaging_main_frame .only_use_frame .col-md-4 img{    margin-left: 0%;
width: 100%;
}
.start_design_page{width:100%;}
.brand_gap_frame{    width: 100%;margin-top: 20px;}
.Brand_Experience{width:100%;}
.middle_banner_img{    margin-top: 10px;
margin-bottom: 20px;    width: 100%;}
.top_cont.VISUAL_ID {
margin-top: 0px;}
.top_cont.VISUAL_ID img {
width: 240px;
margin-right: 0px;
margin-top: 0px;
margin: 0 auto;
display: table;
}
.CREATIVE_SERVICES img {
display: table;
width: 240px;
margin-left: 0px;
margin-top: 0px;
margin: 0 auto;
}
.main_design_frame_right {
margin-top: 20px;
}
.packaging_main_frame .main_design_frame_right .img-responsive_al{    margin-top: 10px;}
.footer_pre_frame img {width: 100%;}
.sustain_right_img.right img{float: none;
margin: 0 auto; }
.sustain_right_img img {
margin: 0 auto;
float: none;
width: 80%;
margin-top: 20px;
}
.visual_all_frame {
margin-top: 20px;
}
.bottom_top_b_frame .col-md-6:last-child img {
width: 100%;
float: none;
margin-top: 20px;
}
.only_use_frame {
margin-top: 20px;
}
.packaging_main_frame .only_use_frame_img {
width: 100%;
}
.brand_express_all .col-md-8{text-align: center;}
.brand_gap_frame img{ margin-bottom: 20px;}
.border_footer{margin: 0 auto;}
footer h4{text-align: center;}
.iamg_frame_all {
height: auto;
}      
.lunch_now_buttomframe .speed_frame .iamg_frame_all.new img {
padding-top: 0px;
}
br{display: none;}
.throuth_frame .col-md-4.col-n img{margin-bottom: 10px;}
.throuth_frame .col-md-7.new p{    text-align: center;margin: 5px 0px;}
.since_frame ul li{width: 100%;float: none;}
.ethi-left_frame ul{    padding-left: 10px;}
.ethi-left_frame img{    margin: 0 auto;
margin-bottom: 20px;}
.makingimg_frame img{margin-top:20px;}
.canhelp_frame_all{    top: 40px;
position: relative;    margin: 0 auto;    width: 90%;}
.header-top p{    text-align: center !important;
margin-bottom: 10px;}
.navbar-header{margin-top:5%;margin-bottom:5%;margin-left:0px !important;}
.navbar-default .navbar-nav>li>a.active:after{left:30px;bottom:0px;}
.navbar-default .navbar-nav>li>a {line-height: 30px;}
.video-frame iframe{height:100%;}
.about-frame-main-middel {width:90%;margin-left:0%;margin-top:0%;margin:0 auto;}
#about-frame{background-position:53% 14%;}
.footer-left-frame img{margin:0 auto;margin-top:40px;}
.footer-left {
float: none;
margin: 0 auto;
text-align: center;
}
.footer-left2{float: none;
margin: 0 auto;
text-align: center;}
.footer-left2 ul{    margin-left: 0px;
padding: 0px;}
.footer-top-heading h3 {
font-weight: bold;
float: none;
text-transform: uppercase;
margin-left: 0px;
text-align: center;
}
.footer-top-heading ul{float:none;    padding: 0px;
text-align: center;}
footer form {
margin: 0 auto;
margin-top: 35px;
width: 80%;
}
footer{    background-position: 1px 1px;}
.right-contact{margin-top:5%;}
.contact-left img{width:80%;margin-bottom: 40px;}

.footer-left p {
margin-left: 0px;}
.all-things-green--you h3 br{display: none;}
.all-things-green--you p br{display: none;} 
.all-things-green--you img {
margin-top: -18%;
}
.not-all-product-all-top h3 br{display: none;}
.not-all-product-all-top p br{display: none;}
.not-all-product-all-bottom{width:100%;text-align: center;}
.not-all-product-all-left h3 br{display:none;}
.not-all-product-all-left p br{display:none;}
.left-img-po img {
width: 60px;
}
.right-img-po img {
width: 60px;
}
.not-all-product-all-right p br{display:none;}
.packging-section-left-bo-img {
margin-left: 0px;
}
.packging-section-right {
margin-top: 10%;
}
.lable-bodyimg-frame img{display:none;
}
.packging-section-right-left {
width: 100%;
float: none;
}
.packging-section-right-right {
width: 100%;
float: none;
}
.labot-section .container {
padding-left: 0px;
}
.labot-section h2{
font-size: 23px;
line-height: 42px;
text-align: center;
}
.labot-section h2 span {font-size: 60px;}
.labot-left {
float: none;
width: 100%;
}
.labot-middle {
float: none;
width: 100%;
padding-left: 10px;
}
.labot-right {
float: none;
width: 100%;
}
.labot-right img {
width: 100%;
margin-top: 0px;
}
.labotman-section-left {
width: 100%;
float: none;
}
.labotman-section-middle {
width: 100%;
float: none;
}
.labotman-section-right {
width: 100%;
float: none;
}
.labotman-section-middle img{    top: 0px;
}
.labotman-section .container {
padding-left: 10px;
}
.labotman-section-main-bottom p{width:100%;}
.hotel-amenities-top-middle p{width:100%;}
.hotel-amenities-top-middle p br{display:none;}
.hotel-amenities-top-middle ~ .hotel-amenities-top-middle-new{display:none;} 



}


@media all and (min-width:120px) and (max-width:767px){
    .bm-menu-wrap {
        background: #08313aad;
        left: 0;
        top: 0;
        width: 100%!important;
        z-index: 99999999 !important;
    }
    .bm-menu-wrap .bm-menu {
        background: #fff;
        width: 70%!important;
        position: relative;
    }
    .mobilemenu-new .accordion__button{
        background-color: #fff;
        position: relative;
    }
    .bm-menu-wrap .bm-cross-button {
        height: 100%!important;
        width: 15%!important;
    }
    .bm-cross {
        background: #fff;
    }
    .dropdown.mobilemenu-new .accordion__button:after {
        border-bottom: 2px solid;
        border-right: 2px solid;
        content: "";
        display: inline-block;
        height: 10px;
        margin-right: 12px;
        position: absolute;
        right: 10px;
        top: 23px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 10px;
    }
    .dropdown.mobilemenu-new .accordion__button:before {
        content: inherit;
    }
    .bm-overlay {
        background: transparent!important;
        right: 0;
        width: 0!important;
    }
    .bm-burger-button {
        height: 30px;
        position: absolute;
        right: 0;
        top: 10px;
        width: 36px;
    }
    .bm-burger-bars {
        background-color: #414141!important;
        border-radius: 1px;
        display: block;
        height: 3px!important;
        margin-bottom: 5px;
        opacity: 1!important;
        position: relative!important;
        top: 0!important;
        width: 28px;
    }
    .dropdown.mobilemenu-new .accordion__item:nth-child(1) .accordion__button::after {
        content: inherit;
    }
    .dropdown.mobilemenu-new .accordion__item:nth-child(4) .accordion__button::after {
        content: inherit;
    }
    .dropdown.mobilemenu-new .accordion__item:nth-child(5) .accordion__button::after {
        content: inherit;
    }
    .dropdown.mobilemenu-new .accordion__item:nth-child(6) .accordion__button::after {
        content: inherit;
    }
    .dropdown.mobilemenu-new .accordion__item:nth-child(7) .accordion__button::after {
        content: inherit;
    }
    .dropdown.mobilemenu-new .Menu-underLink li a {
        color: #414141!important;
        display: inline-block;
        padding: 8px 0;
    }
    .dropdown.mobilemenu-new .Menu-underLink li{
        list-style-type: none;
    }
.img-responsive_all{width: 100%;}
.col-md-12.mobile {
display: inherit;
}
.logo img {
width: 120px;
margin-top: 0px;
}
.Mobile_menu{display: initial;float: right;
margin-top: -65px;}
.row.desktop{display: none;}
.Mobile_menu .icon-bar {
background-color: #888;
display: block;
width: 22px;
height: 2px;
border-radius: 1px;

}
.Mobile_menu .icon-bar+.icon-bar {
margin-top: 4px;
}
.Mobile_menu .button{
background-color: #ddd;
padding: 9px 10px;
width: 42px;
float: right;
}

.Mobile_menu .dropdown{    position: relative;}
.Mobile_menu .button ~ ul{    position: absolute;
left: 0px;
top: 0px;
z-index: 99999;
background: #fff;
width: 100%;
padding: 5px 20px;
border-top: 1px solid #ccc;
margin-top:0px;}

.Mobile_menu .button ~ ul li{    list-style-type: none;}
.Mobile_menu .button ~ ul li a{  
text-decoration: none !important;
font-size: 17px; 
line-height: 28px;
font-family: 'Raleway', sans-serif;
border-bottom: 1px solid #ccccccb8;
color: #000;
padding-top: 5px;
padding-bottom: 5px;
position: relative;
display: block;
}
.Mobile_menu .button ~ ul a:hover{}
.logo img{margin-bottom: 5%;}

.react-tabs__tab{
font-size: 15px;
padding: 10px 4px;
width: 50%;
text-align: center;
}
.container.Cate{
    margin-top: 5%;
}
.home-catelist ul li{
    width: 50%;
display: inline-block;
margin: 10px 0px;
}
.home-catelist ul{
    padding-left: 30px;
    margin-bottom: 0px;
    display: contents;
}
.home-catelist.row{padding-left: 30px;}
    
.makeup-middle-sect .bea-t-right{    width: 80%;}
.makeup-middle-sect .bea-t{ font-size: 50px; width: 36px;}
.gi_f_banner_water p{    width: 100%;}
.img_left img {
    padding-left: 0px;
}
.makeup-middle-sect .lip_acc .bea-t-right {
    width: 85%;
}
.makeup-middle-sect .lip_acc p {
    margin-top: 5%;
}
.rech_now_cont h5{    font-size: 24px;}
.makeup-middle-sect.Body-care.mencare .percare_care p .bea-t{    font-size: 70px;}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont p .bea-t-right {
    width: 85%;
}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont .row:nth-child(1) p .bea-t{
font-size: 80px;
}
.makeup-middle-sect.Body-care.mencare .mencare_mid_cont p .bea-t{font-size: 60px;}
.makeup-middle-sect.Body-care .percare_care p .bea-t {
font-size: 80px;
left: 0px;
}
.makeup-middle-sect.Body-care .Gob_care p .bea-t {
left: 0px;
}
.row.longer .col-md-4 img {
width: 100%;
}
.makeup-middle-sect.Body-care .longer p .bea-t {
left: 0px;
}
.makeup-middle-sect.Body-care .green_na p .bea-t {
left: -10px;
font-size: 49px;
top: 5px;
}
.green_na_g img {
width: 100%;
margin-top: 5%;
}
.makeup-middle-sect.Body-care.mencare_new p .bea-t{left: 10px !important;}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row .bea-t {
left: 0px !important;
top: 20px;
}
.makeup-middle-sect.Body-care.mencare_new .mencare_mid_cont .row- .bea-t.w_w {
left: -30px !important;
}



}

footer textarea{height:80px !important;} 
.blog-img-frame a{text-decoration: none !important;}
.blog-img-frame h3{ color: #000; font-weight: bold; font-size: 18px; height: 50px; }
.blog-img-frame p{color: #000;}      

@media all and (min-width:120px) and (max-width:767px){
    .contactus-page h1 {font-size: 30px;} 
.BakuchiolBlog-page_cont .container {max-width: 100%;}
.BakuchiolBlog-page_cont .container.new {max-width: 100%;}
.Pimples-page .Image_frame_bakuchiol img {width: 70%; }
.blog-img-frame h3{height: auto;}
.CustomFor_cutat1.new .img-responsive-brand{width: 80%;}
.top_main_page_ctstom2 .middle_img_frame_custom-1{margin-bottom: 5%;}
.how-it-word_custfor img.img-responsive-work {width: 90%;margin-bottom: 6%;}
.padding {padding-left: 15px !important;padding-right: 15px !important;}
.custom-3new_page fieldset .img-responsive-osmetic { width: 90%;}
.custom-3new_page fieldset p{width: 100%;}
.custom-3new_page fieldset{width: 100%;}
.product_fl_main_middle p{ height: 100%;}
.product_fl_main-new-bottom_heading .img-responsive-client {
width: 80%;
}
.product_fl_main_middle-client img{margin-bottom: 5%;}
.bespoke_main_row.long-to .text-center .img-responsive-development {    width: 90%; margin-bottom: 9%; }
.overlay-bottom-slide{    position: relative; opacity: 11;     height: auto;}
.text{position: relative;    top: initial; left: inherit;    transform: initial;} 
.product_fl_main-new-bottom_heading{    margin-top: 0px;} 
.Last_buttom_filecus { width: 100%; }
.top-back-banner_all img{width: 100%;margin-bottom: 1%;}
.top_main_page_ctstom1 h2 {font-size: 24px;}     
.product_fl_main.Mobile{display: initial;}
.product_fl_main.desktop{display: none;} 
.bespoke_main_row{margin-bottom: 5%;}   
.bespoke_main_bottom_led.Ne h4 span b {
left: 0px;
position: relative;
font-size: 30px;
top: 0px;
}   
.bespoke_main_bottom_led.Ne h4 span b.bespoke {
left: 0px;
}
.bespoke_main_bottom_led.Ne h4 span b.guidence {
left: 0px;
top: 0px;
}
.bespoke_main_bottom_led.Ne h4 span b.research {
left: 0px;
}
.bespoke_main_bottom_led.Ne h4 span b.prototype {
left: 0px;
}
.bespoke_main_bottom_led.Ne h4 span b.stability {
left: 0px;
top: 0px;
}  

.Last_buttom_filecus.Mobile{display:initial;} 
.Last_buttom_filecus.desktop{display:none;}  
.g-comming-elt img{ width: 90%;}   
.Last_buttom_filecus video{ margin-bottom: 2%; }   
.Last_buttom_filecus img{ margin-bottom: 2%; }   
}   

.whatsapp_chatbot{
    width: auto; 
    bottom: 15px;
}